import React from 'react';
import {WrapperProps} from "../types/Wrapper";

const Wrapper: React.FC<React.PropsWithChildren<WrapperProps>> = ({
                                                                      title,
                                                                      titleColorClass,
                                                                      children
                                                                  }) => (
    <main
        className="flex min-h-screen flex-col items-stretch justify-start w-full max-w-[100vw] p-3 sm:p-8 lg:p-16 sm:pl-72 lg:pl-80 print:sm:pl-16 print:lg:pl-16">

        {!!title && (
            <h1 className={(titleColorClass ?? 'text-gray-800') + " text-center font-bold text-2xl sm:text-3xl lg:text-4xl xl:text-5xl my-6 lg:my-12 print:hidden"}>{title}</h1>)}
        {children}
    </main>
);

export default Wrapper;