
import React, {useCallback, useEffect, useState} from 'react';

const ShowCookieModalBtn = () => {
    const [_document, set_document] = useState<Document>();
    useEffect(() => {
        if (typeof window !== 'undefined') {
            set_document(window.document);
        }
    }, []);
    const show = useCallback(() => {
        if (_document) {
            _document.getElementById("show-cookie-modal")?.click();
        }
    }, [_document]);
    return (
        <button onClick={show}
                className="text-gray-500 hover:text-gray-900 dark:hover:text-white">Cookies
        </button>
    );
}

export default ShowCookieModalBtn;