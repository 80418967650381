/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, {useCallback, useMemo, useState} from 'react';
import QRCode from 'react-qr-code';
import {HasSharedProps} from "../../types/HasShared";
import PlanView from "../PlanView";

const HasShared: React.FC<HasSharedProps> = ({plan}) => {
    const [popup, setPopup] = useState(false);
    const link = useMemo(() => {
        return `https://${window.location.host}/plan?share=${plan.id}`;
    }, [plan.id]);
    const onCopy = useCallback(() => {
        navigator.clipboard.writeText(link).then(() => {
            setPopup(true);
            setTimeout(() => {
                setPopup(false);
            }, 10000);
        });
    }, [link]);
    const onPrint = useCallback(() => {
        window.print();
    }, []);
    return (
        <div className="flex flex-col items-stretch justify-start my-4 print:mt-5">
            <span className="text-success print:hidden">Der Plan wurde in der Cloud gespeichert, sie können hier die Anleitung Ausdrucken oder den Link kopieren</span>
            <h5 className="text-lg font-bold mt-2 print:hidden">Link:</h5>
            <div className="p-2 mb-1 bg-accent-50 rounded-lg text-center print:hidden">
            <span
                className="select-text selection:bg-accent font-mono print:hidden text-xs sm:text-sm lg:text-lg">{link}</span>
            </div>
            <button onClick={onCopy}
                    className="rounded-lg print:hidden flex flex-row items-center justify-center py-2 px-4 border border-secondary hover:bg-gray-400">
                <svg className="w-4 h-4 mr-3" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"
                     fillRule="evenodd"
                     clipRule="evenodd">
                    <path
                        d="M20 24h-20v-22h3c1.229 0 2.18-1.084 3-2h8c.82.916 1.771 2 3 2h3v9h-2v-7h-4l-2 2h-3.898l-2.102-2h-4v18h16v-5h2v7zm-10-4h-6v-1h6v1zm0-2h-6v-1h6v1zm6-5h8v2h-8v3l-5-4 5-4v3zm-6 3h-6v-1h6v1zm0-2h-6v-1h6v1zm0-2h-6v-1h6v1zm0-2h-6v-1h6v1zm-1-7c0 .552.448 1 1 1s1-.448 1-1-.448-1-1-1-1 .448-1 1z"/>
                </svg>
                <span className="hidden sm:inline-block">In die Zwischenablage Kopieren</span>
                <span className="sm:hidden">Kopieren</span>
            </button>
            <span className="mt-6 print:hidden">oder</span>
            <button onClick={onPrint}
                    className="rounded-lg mt-6 mb-2 print:hidden flex flex-row items-center justify-center py-2 px-4 border border-secondary hover:bg-gray-400">
                <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4 mr-3"
                     viewBox="0 0 24 24">
                    <path
                        d="M20 5v-4h-16v4h-4v14h2.764l2.001-4h14.472l2 4h2.763v-14h-4zm-14-2h12v2h-12v-2zm16 13.055l-1.527-3.055h-16.944l-1.529 3.056v-9.056h20v9.055zm-4 .945l3 6h-18l3-6h2.203l-1.968 4h11.528l-1.956-4h2.193zm3-8.5c0 .276-.224.5-.5.5s-.5-.224-.5-.5.224-.5.5-.5.5.224.5.5z"/>
                </svg>
                <span>Anleitung Ausdrucken</span>
            </button>
            <div
                className="max-w-full mx-auto flex flex-col border border-gray-500 p-3 sm:p-12 print:border-0">
                <div
                    className="flex flex-col sm:flex-row print:flex-row items-center justify-evenly">
                    <div className="my-2 sm:my-4 sm:mr-4 print:mr-4 print:my-4">
                        <p className="print:my-2">
                            Ein <span className="font-bold">Fitalps Trainingsplan</span> wurde für
                            Sie freigegeben. Sie können mit folgenden <span
                            className="font-bold">Link</span> darauf zugreifen oder scannen Sie den
                            <span
                                className="font-bold"> QR-Code</span>.
                        </p>
                        <br/>
                        <div className="p-2 bg-accent-50 rounded-lg text-center">
            <span
                className="select-text selection:bg-accent font-mono text-wrap text-xs sm:text-sm lg:text-lg print:hidden">{link}</span>
                            <a href={link}
                               className="hidden print:inline select-text selection:bg-accent font-mono text-wrap text-lg">{link}</a>
                        </div>
                    </div>
                    <QRCode value={link} className="min-w-16 sm:min-w-32 print:min-w-32"
                            size={128}/>
                </div>
                <PlanView plan={plan} hideIcons customLink={link}/>
            </div>
            {popup && (
                <div className="fixed bottom-0 w-[100vw] left-0">
                    <div
                        className="rounded-xl border border-success bg-background flex flex-row items-center justify-center m-4 p-4">
                        <svg xmlns="http://www.w3.org/2000/svg"
                             className="w-8 h-8 mr-6 text-success"
                             viewBox="0 0 24 24">
                            <path
                                d="M21.856 10.303c.086.554.144 1.118.144 1.697 0 6.075-4.925 11-11 11s-11-4.925-11-11 4.925-11 11-11c2.347 0 4.518.741 6.304 1.993l-1.422 1.457c-1.408-.913-3.082-1.45-4.882-1.45-4.962 0-9 4.038-9 9s4.038 9 9 9c4.894 0 8.879-3.928 8.99-8.795l1.866-1.902zm-.952-8.136l-9.404 9.639-3.843-3.614-3.095 3.098 6.938 6.71 12.5-12.737-3.096-3.096z"
                                stroke="currentColor"/>
                        </svg>
                        <span className="font-semibold text-success">Der link wurde erfolgreich in die Zwischenablage kopiert!</span>
                    </div>
                </div>
            )}
        </div>
    );
}

export default HasShared;