/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, {ChangeEvent, useCallback, useEffect, useState} from 'react';
import {FULLSCREEN_SETTING, WAIT_DURATION_SETTING} from "../../config/constants";
import {AutoplayVideosViewProps} from "../../types/AutoplayVideosView";
import {
    PlaybackEndedEvent,
    PlaybackPausedEvent,
    PlaybackResumedEvent,
    PlayNextEvent,
    StartPlaybackEvent
} from "../../config/events";
import VideoView from "../VideoView";
import {catOfVideo} from "../../config/videos";

function getWaitDur() {
    if (typeof localStorage === 'undefined') {
        return 1000;
    }
    const wd = localStorage.getItem(WAIT_DURATION_SETTING);
    if (!wd)
        return 1000;
    const asNum = parseInt(wd, 10);
    if (Number.isNaN(asNum))
        return 1000;
    return asNum;
}

function getFullscreen() {

    if (typeof localStorage === 'undefined') {
        return true;
    }
    const wd = localStorage.getItem(FULLSCREEN_SETTING);
    if (!wd)
        return true;
    const asNum = parseInt(wd, 10);
    if (Number.isNaN(asNum))
        return true;
    return asNum === 1;
}

const AutoplayVideosView: React.FC<AutoplayVideosViewProps> = ({
                                                                   videos,
                                                                   removeIcon,
                                                                   defaultRepeat
                                                               }) => {
    const [waitDuration, setWaitDuration] = useState(getWaitDur());
    const [fullscreen, setFullscreen] = useState(getFullscreen());
    const [repeat, setRepeat] = useState(defaultRepeat ?? 1);
    const [settingsOpen, setSettingsOpen] = useState(false);
    const [started, setStarted] = useState(false);
    const [paused, setPaused] = useState(false);

    const toggleSettings = useCallback(() => {
        setSettingsOpen(so => !so);
    }, []);
    const onStart = useCallback(() => {
        const event = new StartPlaybackEvent('start_playback', {composed: true}, {
            fullscreen,
            repeat,
            waitDuration
        });
        document.dispatchEvent(event);
        setStarted(true);
        toggleSettings();

    }, [fullscreen, repeat, waitDuration, toggleSettings]);

    const onStop = useCallback(() => {
        const event = new PlaybackEndedEvent('playback_ended', {composed: true});
        document.dispatchEvent(event);

    }, []);
    const onPause = useCallback(() => {
        const event = new PlaybackPausedEvent('playback_paused', {composed: true});
        document.dispatchEvent(event);
        setPaused(true);

    }, []);
    const onResume = useCallback(() => {
        const event = new PlaybackResumedEvent('playback_resumed', {composed: true});
        document.dispatchEvent(event);
        setPaused(false);
    }, []);
    const playbackEndedHandler = useCallback(() => {
        setStarted(false);
    }, []);
    useEffect(() => {
        document.addEventListener('playback_ended', playbackEndedHandler);
        return () => {
            document.removeEventListener('playback_ended', playbackEndedHandler);
        }

    }, [playbackEndedHandler]);
    const onPlayNextHandler = useCallback((e: PlayNextEvent) => {
        if (e.index >= videos.length) {
            onPause();
        }
    }, [onPause, videos.length]);
    useEffect(() => {

        // @ts-ignore
        document.addEventListener('play_next', onPlayNextHandler);
        return () => {
            // @ts-ignore
            document.removeEventListener('play_next', onPlayNextHandler);
        }

    }, [onPlayNextHandler]);
    const onChangeRepeat = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        const v = e.target.value;
        const asNum = parseInt(v, 10);

        if (!Number.isNaN(asNum)) {
            setRepeat(asNum);
        }
    }, []);
    const onChangeWaitDur = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        const v = e.target.value;
        const asNum = parseInt(v, 10);
        if (!Number.isNaN(asNum)) {
            if (typeof localStorage !== 'undefined')
                localStorage.setItem(WAIT_DURATION_SETTING, (asNum * 1000).toString(10));
            setWaitDuration(asNum * 1000);
        }
    }, []);
    const onChangeFullscreen = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        if (typeof localStorage !== 'undefined')
            localStorage.setItem(FULLSCREEN_SETTING, e.target.checked ? '1' : '0');
        setFullscreen(e.target.checked);
    }, []);
    return (
        <>

            <div
                className="mt-2 min-w-full grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4">
                {videos.map((vid, i) => {
                    return (
                        <VideoView
                            callbackIndex={i}
                            key={"planvideo-" + vid.id}
                            video={vid}
                            removeIcon={removeIcon}
                            color={catOfVideo(vid.id)?.color ?? 'transparent'}
                            colorCss={catOfVideo(vid.id)?.colorCss ?? 'transparent'}/>
                    );
                })}
            </div>
            <button
                className={(started ? 'z-50 top-1 right-1 py-1 px-1 ' : 'z-20 top-4 right-4 py-2 px-4 ') + " bg-gray-100 dark:bg-gray-900 fixed border-2 border-accent rounded-full flex flex-row items-center justify-center"}
                onClick={started ? (paused ? onResume : onPause) : toggleSettings}>
                <svg viewBox="0 0 24 24" className={"w-5 h-5 " + (started ? '' : 'mr-1')}>
                    {started && !paused ? (
                        <path
                            d="M11 22h-4v-20h4v20zm6-20h-4v20h4v-20z"></path>
                    ) : (
                        <path
                            d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-3 17v-10l9 5.146-9 4.854z"></path>
                    )}
                </svg>
                <span>
                    {started ? '' : 'Automatisch Abspielen'}
                </span>
            </button>
            {started && (
                <button
                    className={"z-50 bg-gray-100 dark:bg-gray-900 fixed top-1 right-12 py-1 px-1 border-2 border-accent rounded-full flex flex-row items-center justify-center"}
                    onClick={onStop}>
                    <svg viewBox="0 0 24 24" className={"w-5 h-5"}>
                        <path
                            d="M2 2h20v20h-20z"></path>
                    </svg>
                </button>
            )}
            <div
                className={"z-30 bg-gray-100 dark:bg-gray-900 fixed space-y-4 top-4 right-0 p-4 border-2 border-accent rounded-l flex flex-col" + (settingsOpen ? '' : ' hidden')}>
                <div className="flex flex-row items-center justify-between">
                    <h6 className="font-bold">Einstellungen</h6>
                    <button type="button" onClick={toggleSettings}
                            className="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
                        <svg className="w-3 h-3"
                             xmlns="http://www.w3.org/2000/svg" fill="none"
                             viewBox="0 0 14 14">
                            <path stroke="currentColor" strokeLinecap="round"
                                  strokeLinejoin="round" strokeWidth="2"
                                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                        <span className="sr-only">Schließen</span>
                    </button>
                </div>
                <div>
                    <label htmlFor="wait-duration"
                           className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Wartezeit
                        zwischen Videos in Sekunden</label>
                    <input type="number" name="wait-duration" id="wait-duration"
                           onChange={onChangeWaitDur}
                           className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                           defaultValue={waitDuration / 1000} required/>
                </div>
                <div>
                    <label htmlFor="repeat"
                           className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Wiederholungen</label>
                    <input type="number" name="repeat" id="repeat" onChange={onChangeRepeat}
                           className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                           defaultValue={repeat} required/>
                </div>
                <div>
                    <label htmlFor="fullscreen"
                           className="mb-2 text-sm font-medium text-gray-900 dark:text-white flex flex-row items-center justify-between">Vollbild
                        <input type="checkbox" name="fullscreen" id="fullscreen"
                               onChange={onChangeFullscreen}
                               className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-4 h-4 p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                               checked={fullscreen} required/>
                    </label>
                </div>
                <button onClick={onStart}
                        className="font-bold border border-accent py-2 px-4 rounded-lg">Videos
                    abspielen
                </button>
            </div>
        </>
    );
}

export default AutoplayVideosView;