import React from "react";
import Wrapper from "../Wrapper";
import PlanView from "../PlanView";
import {storedPlans} from "../../config/plans";

export default function ProgramsMobilS() {
    return (
        <Wrapper title="Mobilisationsprogramme Schulter">
            <div
                className="min-w-full flex flex-col">
                <PlanView plan={storedPlans[6]}/>
                <PlanView plan={storedPlans[7]}/>
                <PlanView plan={storedPlans[8]}/>
                <PlanView plan={storedPlans[9]}/>
            </div>
            <div className="mt-4">
                <p>Alle Mobilisationsprogramme sind Musterbeispiele und als Hilfe für deine
                    Trainingsplanung gedacht. Der Therapeut passt das Programm individuell an deine
                    Anforderungen an und steht dir bei einer indikationsspezifischen Einweisung zur
                    Seite.</p>
                <br/>
                <p>Allgemein dienen 2 bis 3 Serien pro Trainingsprogramm mit je 5 bis 15
                    Wiederholungen pro Übung als Anhaltspunkt, wobei eine Anpassung an dein
                    individuelles Trainingsziel und Leistungsniveau erfolgt. Die
                    Mobilisationsprogramme können täglich durchgeführt werden. </p>
                <br/>
                <p>Jede Übung wird langsam und kontrolliert durchgeführt – eine aktive
                    Rumpfstabilisation ist dabei besonders wichtig. Bei Schmerzen stoppe bitte
                    unverzüglich das Training und kontaktiere deinen Therapeuten.</p>
            </div>
        </Wrapper>
    )
}
