import {YEAR_IN_MS} from "./constants";

export function generateCookieString(id: string, value: string, exp: number = 10 * YEAR_IN_MS): string {
    const d = new Date();
    d.setTime(d.getTime() + exp);
    return `${id}=${value}; path=/; expires=${d.toUTCString()}`;
}

export function includesId<T extends { id: string }>(array: T[], searchElement: string) {
    return !!array.find(elem => elem.id === searchElement);
}

export function generatePW(length = 12, bigCharset = false): string {
    let charset =
        'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ01234567890123456789';
    if (bigCharset) {
        charset += '-_#+*!§$%&/()=?ß{[]}';
    }
    let retVal = '';
    for (let i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
}
