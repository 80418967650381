import React, {ChangeEvent, useCallback, useContext, useMemo, useState} from 'react';
import {ShareIconProps} from "../../types/ShareIcon";
import {PlanContext} from "../../config/contexts";
import {generatePW} from "../../config/utils";
import {useNavigate} from "react-router";

const ShareIcon: React.FC<ShareIconProps> = ({plan}) => {
    const {plans} = useContext(PlanContext);
    const [wantShare, setWantShare] = useState(false);
    const [name, setName] = useState(plan.name);
    const nav = useNavigate();
    const [desc, setDesc] = useState(plan.desc ?? "");
    const setNameCont = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value);
    }, []);
    const setDescCont = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
        setDesc(e.target.value);
    }, []);
    const alreadyInPlan = useMemo(() => {
        return plans.find(p => p.id === plan.id);
    }, [plans, plan.id]);
    const onShare = useCallback(() => {
        setWantShare(true)

    }, []);
    const onClose = useCallback(() => {
        setWantShare(false)
    }, []);
    const shareId = useMemo(() => {
        return generatePW(12);
    }, []);
    const onShareCont = useCallback(() => {
        const fd = new FormData();
        fd.append("name", name);
        fd.append("desc", desc);
        fd.append("share-id", shareId);
        fd.append("videos", plan.videos.map(v => v.id).join(","));
        fetch("/save", {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                name,
                desc,
                "share-id": shareId,
                id: shareId,
                videos: plan.videos
            })
        }).then((res) => {
            if (res.status === 200) {
                nav("/plan?shared=" + shareId);
            }
        }, () => {
            //TODO
        })
    }, []);
    if (!alreadyInPlan)
        return null;
    return (
        <>
            <button type="button" onClick={onShare}
                    className={"border-0 outline-0 transition duration-75 text-gray-900 dark:text-white relative"}>
                <svg
                    className={"flex-shrink-0 w-6 h-6 2xl:w-10 2xl:h-10 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white"}
                    xmlns="http://www.w3.org/2000/svg" fill="currentColor" fillRule="evenodd"
                    viewBox="0 0 24 24">
                    <path
                        d="M16.272 5.451c-.176-.45-.272-.939-.272-1.451 0-2.208 1.792-4 4-4s4 1.792 4 4-1.792 4-4 4c-1.339 0-2.525-.659-3.251-1.67l-7.131 3.751c.246.591.382 1.239.382 1.919 0 .681-.136 1.33-.384 1.922l7.131 3.751c.726-1.013 1.913-1.673 3.253-1.673 2.208 0 4 1.792 4 4s-1.792 4-4 4-4-1.792-4-4c0-.51.096-.999.27-1.447l-7.129-3.751c-.9 1.326-2.419 2.198-4.141 2.198-2.76 0-5-2.24-5-5s2.24-5 5-5c1.723 0 3.243.873 4.143 2.201l7.129-3.75zm3.728 11.549c1.656 0 3 1.344 3 3s-1.344 3-3 3-3-1.344-3-3 1.344-3 3-3zm-15-9c2.208 0 4 1.792 4 4s-1.792 4-4 4-4-1.792-4-4 1.792-4 4-4zm15-7c1.656 0 3 1.344 3 3s-1.344 3-3 3-3-1.344-3-3 1.344-3 3-3z"/>
                </svg>
            </button>
            {wantShare && (
                <div
                    className="overflow-y-auto overflow-x-hidden fixed top-0 left-0 right-0 z-50 flex flex-row justify-center items-center w-full md:inset-0 h-[100%] max-h-full">
                    <div className="relative p-4 w-full max-w-2xl max-h-full">
                        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                            <div
                                className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                                <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                                    Trainingsplan teilen
                                </h3>
                                <button type="button" onClick={onClose}
                                        className="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
                                    <svg className="w-3 h-3"
                                         xmlns="http://www.w3.org/2000/svg" fill="none"
                                         viewBox="0 0 14 14">
                                        <path stroke="currentColor" strokeLinecap="round"
                                              strokeLinejoin="round" strokeWidth="2"
                                              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                                    </svg>
                                    <span className="sr-only">Schließen</span>
                                </button>
                            </div>
                            <div className="p-4 md:p-5">
                                <div className="space-y-4">
                                    <div>
                                        <label htmlFor="name"
                                               className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Name</label>
                                        <input type="text" name="name" id="name"
                                               className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                                               value={name} onChange={setNameCont} required/>
                                    </div>
                                    <div>
                                        <label htmlFor="desc"
                                               className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Beschreibung</label>
                                        <textarea name="desc" id="desc" value={desc}
                                                  onChange={setDescCont}
                                                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                                        >

                                        </textarea>
                                    </div>
                                    <button type="button" onClick={onShareCont}
                                            className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                        Teilen
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default ShareIcon;