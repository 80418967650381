import React, {useContext, useMemo} from 'react';
import {FavoriteContext} from "../../config/contexts";
import {Video} from "../../types/videos";
import {findVideo} from "../../config/videos";
import AutoplayVideosView from "./AutoplayVideosView";

const FavoritesView = () => {
    const {favs} = useContext(FavoriteContext);
    const favVideos = useMemo<Video[]>(() => {
        return favs.map(f => findVideo(f)).filter(v => !!v) as Video[];
    }, [favs]);
    return (
        <React.Fragment>
            <AutoplayVideosView videos={favVideos}/>

            {favVideos.length === 0 && (
                <div
                    className="mt-2 min-w-full grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4">

                    <span>Keine Lieblingsvideos gespeichert</span>
                </div>
            )}
        </React.Fragment>
    );
}

export default FavoritesView;