import React, {ChangeEvent, useCallback, useContext, useMemo, useState} from 'react';
import {EditPlanIconProps} from "../../types/EditPlanIcon";
import {PlanContext} from "../../config/contexts";
import PlanIcon from "./PlanIcon";

const EditPlanIcon: React.FC<EditPlanIconProps> = ({plan}) => {
    const {plans, setPlans} = useContext(PlanContext);
    const [open, setOpen] = useState(false);
    const [newName, setNewName] = useState(plan.name);
    const [newDesc, setNewDesc] = useState(plan.desc);
    const toggleOpen = useCallback(() => {
        setOpen(o => !o);
    }, []);
    const onSave = useCallback(() => {
        setPlans(plans.map((p) => {
            if (p.id === plan.id) {
                return {
                    ...p,
                    name: newName,
                    desc: newDesc
                }
            }
            return p;
        }));
        setOpen(false);
    }, [newDesc, newName, plan.id, plans, setPlans]);
    const onDelete = useCallback(() => {
        setPlans(plans.filter(p => p.id !== plan.id));

    }, [plan.id, plans, setPlans]);
    const savedPlan = useMemo(() => {
        return plans.find((p) => p.id === plan.id);
    }, [plans, plan.id]);
    const onChangeName = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        setNewName(e.target.value);
    }, []);
    const onChangeDesc = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
        setNewDesc(e.target.value);
    }, []);
    if (!savedPlan) {
        return <PlanIcon plan={plan}/>;
    }
    return (
        <>
            <button onClick={toggleOpen}
                    type="button" title={"Aus meiner Liste entfernen"}
                    className={"border-0 outline-0 transition duration-75 text-gray-900 dark:text-white relative flex flex-row items-center justify-center"}>
                <span className="mr-2 hidden sm:inline-block">Bearbeiten</span>
                <svg
                    className={"flex-shrink-0 w-6 h-6 2xl:w-10 2xl:h-10 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white"}
                    xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                    fillRule="evenodd"
                    strokeMiterlimit={2}
                    strokeLinejoin={"round"}
                    viewBox="0 0 24 24">
                    <path
                        d="m11.25 6c.398 0 .75.352.75.75 0 .414-.336.75-.75.75-1.505 0-7.75 0-7.75 0v12h17v-8.75c0-.414.336-.75.75-.75s.75.336.75.75v9.25c0 .621-.522 1-1 1h-18c-.48 0-1-.379-1-1v-13c0-.481.38-1 1-1zm-2.011 6.526c-1.045 3.003-1.238 3.45-1.238 3.84 0 .441.385.626.627.626.272 0 1.108-.301 3.829-1.249zm.888-.889 3.22 3.22 8.408-8.4c.163-.163.245-.377.245-.592 0-.213-.082-.427-.245-.591-.58-.578-1.458-1.457-2.039-2.036-.163-.163-.377-.245-.591-.245-.213 0-.428.082-.592.245z"/>
                </svg>
            </button>
            <div
                className={"overflow-y-auto overflow-x-hidden fixed top-0 left-0 right-0 sm:right-auto z-50 flex flex-row justify-center items-center w-full md:inset-0 h-[100%] max-h-full" + (open ? '' : ' hidden')}>
                <div className="relative p-4 w-full max-w-2xl max-h-full">
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        <div
                            className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                                Bearbeiten
                            </h3>
                            <button type="button" onClick={toggleOpen}
                                    className="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
                                <svg className="w-3 h-3"
                                     xmlns="http://www.w3.org/2000/svg" fill="none"
                                     viewBox="0 0 14 14">
                                    <path stroke="currentColor" strokeLinecap="round"
                                          strokeLinejoin="round" strokeWidth="2"
                                          d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                                </svg>
                                <span className="sr-only">Schließen</span>
                            </button>
                        </div>
                        <div className="p-4 md:p-5 space-y-4">
                            <div>
                                <label htmlFor="name"
                                       className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Name</label>
                                <input type="text" name="name" id="name"
                                       className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                                       value={newName} onChange={onChangeName} required/>
                            </div>
                            <div>
                                <label htmlFor="desc"
                                       className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Beschreibung</label>
                                <textarea name="desc" id="desc" value={newDesc}
                                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                                          onChange={onChangeDesc}>
                                </textarea>
                            </div>
                        </div>
                        <div
                            className="flex flex-row items-center justify-between p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">

                            <button type="button" onClick={onDelete}
                                    className="ms-3 text-white bg-red-500 hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 focus:z-10">Löschen
                            </button>
                            <button type="button" onClick={onSave}
                                    className="ms-3 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Speichern
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default EditPlanIcon;