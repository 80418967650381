import React, {useCallback, useContext, useMemo, useState} from 'react';
import {PlanIconProps} from "../../types/PlanIcon";
import {PlanContext} from "../../config/contexts";

const PlanIcon: React.FC<PlanIconProps> = (props) => {
    const {setOpen, setPlans, plans} = useContext(PlanContext);
    const [wantDelete, setWantDelete] = useState(false);
    const alreadyInPlan = useMemo(() => {
        return plans.find(p => p.id === ('plan' in props ? props.plan.id : 'removeIcon' in props ? props.removeIcon : -1));
    }, [plans, props]);
    const onOpen = useCallback(() => {
        if ('id' in props) {
            setOpen(props.id);
        } else if ('plan' in props) {
            setPlans(plans.concat(props.plan));
            setOpen(true);
        }
    }, [props, setOpen, setPlans, plans]);
    const toggleWantDelete = useCallback(() => {
        if ('plan' in props) {
            setPlans(plans.map(p => ({...p})));
            setWantDelete(wd => !wd);
        }
    }, [props, plans, setPlans]);
    const onReallyDelete = useCallback(() => {
        if ('plan' in props) {
            setPlans(plans.filter(p => p.id !== props.plan.id));
            setWantDelete(false);
        }
    }, [plans, props, setPlans]);
    const removeFromPlan = useCallback(() => {
        if ('removeIcon' in props && 'id' in props) {
            setPlans(plans.map(p => {
                if (p.id === props.removeIcon) {
                    return {
                        ...p,
                        videos: p.videos.filter(v => v.id !== props.id),
                    }
                }
                return p;
            }));

        }
    }, [plans, props, setPlans]);
    if (alreadyInPlan) {
        if ('plan' in props || 'removeIcon' in props) {
            return (
                <>
                    <button onClick={'removeIcon' in props ? removeFromPlan : toggleWantDelete}
                            type="button" title={"Aus meiner Liste entfernen"}
                            className={"border-0 outline-0 transition duration-75 text-gray-900 dark:text-white relative flex flex-row items-center justify-center"}>
                        <span className="mr-2 hidden sm:inline-block">Entfernen</span>
                        <svg
                            className={"flex-shrink-0 w-6 h-6 2xl:w-10 2xl:h-10 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white"}
                            xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                            fillRule="evenodd"
                            strokeMiterlimit={2}
                            strokeLinejoin={"round"}
                            viewBox="0 0 24 24">
                            <path
                                d="m12.002 2.005c5.518 0 9.998 4.48 9.998 9.997 0 5.518-4.48 9.998-9.998 9.998-5.517 0-9.997-4.48-9.997-9.998 0-5.517 4.48-9.997 9.997-9.997zm0 1.5c-4.69 0-8.497 3.807-8.497 8.497s3.807 8.498 8.497 8.498 8.498-3.808 8.498-8.498-3.808-8.497-8.498-8.497zm4.253 7.75h-8.5c-.414 0-.75.336-.75.75s.336.75.75.75h8.5c.414 0 .75-.336.75-.75s-.336-.75-.75-.75z"/>
                        </svg>
                    </button>
                    <div
                        className={"overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full" + (wantDelete ? '' : ' hidden')}>
                        <div className="relative p-4 w-full max-w-2xl max-h-full">
                            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                                <div
                                    className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                                    <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                                        Löschen?
                                    </h3>
                                </div>
                                <div className="p-4 md:p-5 space-y-4">
                                    <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                                        Sicher dass du diesen Trainingsplan aus deiner Liste löschen
                                        möchtest?
                                    </p>
                                </div>
                                <div
                                    className="flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">

                                    <button type="button" onClick={toggleWantDelete}
                                            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Nein
                                    </button>
                                    <button type="button" onClick={onReallyDelete}
                                            className="ms-3 text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">Ja
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )
        }
        return null;
    }
    return (
        <button onClick={onOpen} title="Trainingsplan speichern" type="button"
                className={"border-0 outline-0 transition duration-75 text-gray-900 dark:text-white relative flex flex-row items-center justify-center"}>
            <span className="mr-2 hidden sm:inline-block">Trainingsplan</span>
            <svg
                className={"flex-shrink-0 w-6 h-6 2xl:w-10 2xl:h-10 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white"}
                xmlns="http://www.w3.org/2000/svg" fill="currentColor" fillRule="evenodd"
                strokeMiterlimit={2}
                strokeLinejoin={"round"}
                viewBox="0 0 24 24">
                <path
                    d="m17.5 11c2.484 0 4.5 2.016 4.5 4.5s-2.016 4.5-4.5 4.5-4.5-2.016-4.5-4.5 2.016-4.5 4.5-4.5zm.5 4v-1.5c0-.265-.235-.5-.5-.5s-.5.235-.5.5v1.5h-1.5c-.265 0-.5.235-.5.5s.235.5.5.5h1.5v1.5c0 .265.235.5.5.5s.5-.235.5-.5c0-.592 0-1.5 0-1.5h1.5c.265 0 .5-.235.5-.5s-.235-.5-.5-.5c-.592 0-1.5 0-1.5 0zm-6.479 1c.043.522.153 1.025.321 1.5h-9.092c-.414 0-.75-.336-.75-.75s.336-.75.75-.75zm1.106-4c-.328.456-.594.96-.785 1.5h-9.092c-.414 0-.75-.336-.75-.75s.336-.75.75-.75zm7.373-3.25c0-.414-.336-.75-.75-.75h-16.5c-.414 0-.75.336-.75.75s.336.75.75.75h16.5c.414 0 .75-.336.75-.75zm0-4c0-.414-.336-.75-.75-.75h-16.5c-.414 0-.75.336-.75.75s.336.75.75.75h16.5c.414 0 .75-.336.75-.75z"/>
            </svg>

        </button>
    );
}

export default PlanIcon;