import React, {useCallback, useEffect, useState} from 'react';
import {CookieModalProps} from "../../types/CookieModal";
import {generateCookieString} from "../../config/utils";
import {COOKIE_CONSENT, YEAR_IN_MS} from "../../config/constants";

const CookieModal: React.FC<CookieModalProps> = ({initVis}) => {

    const [visible, setVisible] = useState(initVis);
    const [_document, set_document] = useState<Document>();
    useEffect(() => {
        if (typeof window !== 'undefined') {
            set_document(window.document);
        }
    }, []);
    const accept = useCallback(() => {
        if (_document) {
            _document.cookie = generateCookieString(COOKIE_CONSENT, 'true', 10 * YEAR_IN_MS);
            setVisible(false);
        }
    }, [_document]);
    const decline = useCallback(() => {
        setVisible(false);
    }, []);
    const show = useCallback(() => {
        setVisible(true);
    }, []);
    return (
        <div id="cookie-modal"
             className={"overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full" + (visible ? '' : ' hidden')}>
            <div className="relative p-4 w-full max-w-2xl max-h-full">
                <button id="show-cookie-modal" className="hidden" onClick={show}>Show</button>
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    <div
                        className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                        <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                            Wir benutzen cookies
                        </h3>
                    </div>
                    <div className="p-4 md:p-5 space-y-4">
                        <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                            Wir verwenden Cookies, um Inhalte und Anzeigen zu personalisieren,
                            Funktionen für anbieten zu können und die Zugriffe auf unserer Website
                            zu analysieren. Außerdem geben wir Informationen zu Ihrer Verwendung
                            unserer Website an unsere Partner für soziale Medien, Werbung und
                            Analysen weiter. Unsere Partner führen diese Informationen
                            möglicherweise mit weiteren Daten zusammen, die Sie ihnen bereitgestellt
                            haben oder die sie im Rahmen Ihrer Nutzung der Dienste gesammelt haben.
                        </p>
                        <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                            Des Weiteren findet eine Verarbeitung und Verwendung der Cookies durch
                            Drittanbieter in den USA statt. Es liegt kein Beschluss der Europäischen
                            Kommission und keine Bescheinigung vom EuGH vor, dass es in den USA ein
                            angemessenes Datenschutzniveau gibt. Darüber hinaus wird von den USA
                            keine Sicherheit für den Schutz personenbezogener Daten garantiert.
                            Daher besteht die Möglichkeit, dass personenbezogene Daten zu Kontroll-
                            und Überwachungszwecken durch Behörden herangezogen werden. Gegen diesen
                            Datenzugriff gibt es keine wirksamen rechtlichen Mittel
                        </p>
                    </div>
                    <div
                        className="flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">

                        <button type="button" onClick={accept}
                                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Ich
                            akzeptiere
                        </button>
                        <button type="button" onClick={decline}
                                className="ms-3 text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">Ablehnen
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CookieModal;