import React, {PropsWithChildren, useCallback, useMemo, useState} from 'react';
import {HeaderLinkProps} from "../../types/HeaderLink";
import ChevronDownIcon from "./ChevronDownIcon";
import {Link} from "react-router-dom";
import FavsNumber from "../client/FavsNumber";


const HeaderLink: React.FC<PropsWithChildren<HeaderLinkProps>> = ({
                                                                      pathname,
                                                                      icon,
                                                                      text,
                                                                      children,
                                                                  }) => {
    const [open, setOpen] = useState(false);
    const toggleOpen = useCallback(() => {
        setOpen(o => !o);
    }, []);
    const path = window.location.pathname;
    const isActive = useMemo(() => {
        return path.startsWith(pathname);
    }, [path, pathname]);

    if (typeof children !== 'undefined') {
        return (
            <li>
                <button type="button"
                        className={"flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700" + (isActive ? ' bg-gray-100 dark:bg-gray-700' : '')}
                        onClick={toggleOpen}>
                    {icon && (<svg
                        className={"flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white" + (isActive ? ' text-gray-900 dark:text-white' : '')}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor" viewBox="0 0 24 24">
                        {icon.map(i => (
                            <path key={i}
                                  d={i}/>)
                        )}
                    </svg>)}
                    <span
                        className="flex-1 ms-3 text-left rtl:text-right whitespace-nowrap">{text}</span>
                    <ChevronDownIcon/>
                </button>
                <ul
                    className={(isActive || open ? '' : 'hidden ') + "py-2 space-y-2"}>
                    {children}
                </ul>
            </li>
        );
    }
    return (
        <li>
            <Link to={pathname} type="button"
                  className={"flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group" + (isActive ? ' bg-gray-100 dark:bg-gray-700' : '')}>
                {icon && (<svg
                    className={"flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" + (isActive ? ' text-gray-900 dark:text-white' : '')}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor" viewBox="0 0 24 24">
                    {icon.map(i => (<path key={i}
                                          d={i}/>))}
                </svg>)}
                <span className="flex-1 ms-3 whitespace-nowrap">{text}</span>
                {pathname === '/favorites' && (
                    <FavsNumber/>
                )}
            </Link>
        </li>
    );
}

export default HeaderLink;