import React, {useCallback, useContext, useEffect, useState} from 'react';
import {PlanContext} from "../config/contexts";
import {Link} from "react-router-dom";
import HeaderLink from "./atoms/HeaderLink";
import PlanLink from "./client/PlanLink";
import logo from "../assets/images/logo.webp";

const Header = () => {
    const {open: plansOpen} = useContext(PlanContext);
    const [open, setOpen] = useState(false);
    const toggleOpen = useCallback(() => {
        setOpen(o => !o);
    }, []);
    useEffect(() => {
        setOpen(!!plansOpen);
    }, [plansOpen]);
    const pathname = window.location.pathname;

    useEffect(() => {
        setOpen(false);
    }, [pathname]);
    return (
        <>
            <button type="button" onClick={toggleOpen}
                    className="inline-flex items-center p-2 mt-2 ms-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
                <span className="sr-only">Open sidebar</span>
                <svg className="w-6 h-6" fill="currentColor"
                     viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path clipRule="evenodd" fillRule="evenodd"
                          d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
                </svg>
            </button>
            <div className={"hidden"}>
                <div
                    className={"bg-ftsyellow after:border-t-ftsyellow after:border-l-ftsyellow"}></div>
                <div className={"bg-ftsblue after:border-t-ftsblue after:border-l-ftsblue"}></div>
                <div
                    className={"bg-ftsgreen after:border-t-ftsgreen after:border-l-ftsgreen"}></div>
                <div className={"bg-ftsred after:border-t-ftsred after:border-l-ftsred"}></div>
                <div
                    className={"bg-ftspurple after:border-t-ftspurple after:border-l-ftspurple"}></div>
            </div>
            <button onClick={toggleOpen}
                    className={`fixed z-40 inset-0 bg-black opacity-30 ${open ? '' : 'pointer-events-none hidden'} sm:!hidden sm:!pointer-events-none`}></button>

            <aside
                className={`print:hidden fixed top-0 left-0 z-50 w-64 h-screen transition-transform ${open ? '' : '-translate-x-full '} sm:translate-x-0`}>
                <div className="h-full px-3 py-4 overflow-y-auto bg-gray-50 dark:bg-gray-800">
                    <ul className="space-y-2 font-medium">
                        <li>
                            <Link to="/"
                                  className="p-2 flex items-center justify-center">
                                <img src={logo} alt={'Fitalps'} width={92}
                                     height={100}/>
                            </Link>
                        </li>
                        <HeaderLink text={'Lieblingsvideos'} pathname={"/favorites"}
                                    icon={['m7.234 3.004c-2.652 0-5.234 1.829-5.234 5.177 0 3.725 4.345 7.727 9.303 12.54.194.189.446.283.697.283s.503-.094.697-.283c4.977-4.831 9.303-8.814 9.303-12.54 0-3.353-2.58-5.168-5.229-5.168-1.836 0-3.646.866-4.771 2.554-1.13-1.696-2.935-2.563-4.766-2.563zm0 1.5c1.99.001 3.202 1.353 4.155 2.7.14.198.368.316.611.317.243 0 .471-.117.612-.314.955-1.339 2.19-2.694 4.159-2.694 1.796 0 3.729 1.148 3.729 3.668 0 2.671-2.881 5.673-8.5 11.127-5.454-5.285-8.5-8.389-8.5-11.127 0-1.125.389-2.069 1.124-2.727.673-.604 1.625-.95 2.61-.95z']}/>
                        <PlanLink text={'Mein Trainingsplan'} pathname={"/plan"}
                                  icon={['M6.914 2.741l15.557 15.556-4.243 4.243-15.556-15.557 4.242-4.242zm-.707-.707l-2.033-2.034-4.174 4.243 1.965 2.034 4.242-4.243zm12.821 21.119l4.972.847-.918-4.901-4.054 4.054zm-4.567-15.694l.389-.388.707.707.707-.707-.707-.707.707-.707.707.707.708-.708-.707-.706 2.121-2.121 2.121 2.121-4.631 4.631 1.393 1.393 6.024-6.024-4.908-4.95-6.045 6.045 1.414 1.414zm-4.949 9.193l-4.562 4.561-2.121-2.121.707-.708.707.707.707-.707-.707-.707.707-.707.707.707.707-.707-.707-.707.707-.707.707.707.707-.707-.707-.706.319-.319-1.415-1.415-5.975 5.976 4.95 4.908 5.955-5.955-1.393-1.393zm9.934-11.349c.195-.195.195-.512 0-.707s-.512-.195-.707 0-.195.512 0 .707.512.196.707 0z']}/>
                        <HeaderLink text={'Mobilisation'} pathname={"/m"}
                                    icon={["M16 16l3-8 3.001 8A5.002 5.002 0 0116 16z", "M2 16l3-8 3.001 8A5.002 5.002 0 012 16z", "M7 21h10", "M12 3v18", "M3 7h2c2 0 5-1 7-2 2 1 5 2 7 2h2"]}>
                            <HeaderLink text={"Schulter"} pathname={"/ms"}/>
                            <HeaderLink text={"Wirbelsäule"} pathname={"/mw"}/>
                        </HeaderLink>
                        <HeaderLink text={'Kräftigung'} pathname={"/k"}
                                    icon={["M9.14,16.77S8,13.17,10.09,11A14.12,14.12,0,0,1,13,9.13a4.78,4.78,0,1,1,5.61,4.7c-1.83,2.77-5.83,7.71-11.33,7.71C4.36,21.54,1.5,13,1.5,9.13V4.48A2.26,2.26,0,0,1,3.64,2.23c1.73-.09,4,0,4.54,1.17C9,5.11,7.23,8.18,5.32,8.18c0,1.5,1.83,4.76,3.49,6.56"]}>
                            <HeaderLink text={"Körpergewicht"} pathname={"/kk"}/>
                            <HeaderLink text={"Widerstandsseil"} pathname={"/kw"}/>
                            <HeaderLink text={"Seilzug"} pathname={"/ks"}/>
                        </HeaderLink>
                        <HeaderLink text={"Trainingsprogramme"} pathname={"/programs"}
                                    icon={["m21 4c0-.478-.379-1-1-1h-16c-.62 0-1 .519-1 1v16c0 .621.52 1 1 1h16c.478 0 1-.379 1-1zm-16.5.5h15v15h-15zm6.75 9.25v3.25c0 .53-.47 1-1 1h-3.25c-.53 0-1-.47-1-1v-3.25c0-.53.47-1 1-1h3.25c.53 0 1 .47 1 1zm0-6.75v3.25c0 .53-.47 1-1 1h-3.25c-.53 0-1-.47-1-1v-3.25c0-.53.47-1 1-1h3.25c.53 0 1 .47 1 1zm6.75 0v3.25c0 .53-.47 1-1 1h-3.25c-.53 0-1-.47-1-1v-3.25c0-.53.47-1 1-1h3.25c.53 0 1 .47 1 1z"]}/>
                        <HeaderLink text={"Mobilisationsprogramme"} pathname={"/programs-mobil"}
                                    icon={["m21 4c0-.478-.379-1-1-1h-16c-.62 0-1 .519-1 1v16c0 .621.52 1 1 1h16c.478 0 1-.379 1-1zm-16.5.5h15v15h-15zm6.75 9.25v3.25c0 .53-.47 1-1 1h-3.25c-.53 0-1-.47-1-1v-3.25c0-.53.47-1 1-1h3.25c.53 0 1 .47 1 1zm0-6.75v3.25c0 .53-.47 1-1 1h-3.25c-.53 0-1-.47-1-1v-3.25c0-.53.47-1 1-1h3.25c.53 0 1 .47 1 1zm6.75 0v3.25c0 .53-.47 1-1 1h-3.25c-.53 0-1-.47-1-1v-3.25c0-.53.47-1 1-1h3.25c.53 0 1 .47 1 1z"]}>
                            <HeaderLink text={"Schulter"} pathname={"/programs-mobil-s"}/>
                            <HeaderLink text={"Wirbelsäule"} pathname={"/programs-mobil-w"}/>
                        </HeaderLink>
                    </ul>
                </div>
            </aside>
        </>
    );
}

export default Header;