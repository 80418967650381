/* eslint-disable @typescript-eslint/ban-ts-comment */

import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {VideoProps} from "../types/VideoView";
import {PlayNextEvent, StartPlaybackEvent} from "../config/events";
import {Video} from "../types/videos";
import {
    findVideo,
    pathOfVideoMp4,
    pathOfVideoOgg,
    pathOfVideoWebm,
    posterOfVideo
} from "../config/videos";
import HeartIcon from "./client/HeartIcon";
import PlanIcon from "./client/PlanIcon";


const VideoView: React.FC<VideoProps> = ({
                                             video,
                                             callbackIndex,
                                             colorCss,
                                             category, removeIcon
                                         }) => {
    const ref = useRef<HTMLVideoElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const waitDuration = useRef(500);
    const fullscreen = useRef(true);
    const repeat = useRef(1);
    const playing = useRef(false);
    const [autoplay, setAutoplay] = useState(false);
    const repeated = useRef(0);
    const [_document, set_document] = useState<Document>();
    useEffect(() => {
        if (typeof window !== 'undefined') {
            set_document(window.document);
        }
    }, []);
    const onPlay = useCallback(() => {
        if (ref.current) {
            const v = ref.current;
            if (fullscreen.current && containerRef.current) {
                containerRef.current.classList.remove("video-hidden-bottom")
            }
            setTimeout(() => {

                v.play().then(() => {
                    playing.current = true;
                });
            }, waitDuration.current);
        }
    }, []);
    const onEnd = useCallback(() => {
        if (_document) {
            if (typeof callbackIndex === 'number' && autoplay) {
                if (repeat.current <= repeated.current) {
                    const event = new PlayNextEvent('play_next', {composed: true}, callbackIndex + 1);
                    _document.dispatchEvent(event);
                    playing.current = false;
                    if (fullscreen.current && containerRef.current) {
                        containerRef.current.classList.add("video-hidden-top")
                    }
                } else {
                    repeated.current++;
                    onPlay();
                }
            }
        }
    }, [_document, autoplay, callbackIndex, onPlay]);
    const onPlayNextHandler = useCallback((e: PlayNextEvent) => {
        if (e.index === callbackIndex) {
            onPlay();
        }
    }, [callbackIndex, onPlay]);
    const onStartPlaybackHandler = useCallback((e: StartPlaybackEvent) => {
        waitDuration.current = e.waitDuration;
        repeat.current = e.repeat;
        repeated.current = 1;
        fullscreen.current = e.fullscreen;
        setAutoplay(true)
        if (callbackIndex === 0) {
            onPlay();
        }
    }, [callbackIndex, onPlay]);
    const playbackEndedHandler = useCallback(() => {
        setAutoplay(false);
        playing.current = false;
        if (ref.current) {
            ref.current.pause();
        }
    }, []);
    const playbackPausedHandler = useCallback(() => {
        if (ref.current && playing.current) {
            ref.current.pause();
        }
    }, []);
    const playbackResumeHandler = useCallback(() => {
        if (ref.current && playing.current) {
            ref.current.play().then();
        }
    }, []);
    useEffect(() => {
        // @ts-ignore
        document.addEventListener('play_next', onPlayNextHandler);
        return () => {
            // @ts-ignore
            document.removeEventListener('play_next', onPlayNextHandler);
        }
    }, [onPlayNextHandler]);
    useEffect(() => {
        if (_document) {
            // @ts-ignore
            _document.addEventListener('start_playback', onStartPlaybackHandler);
            return () => {
                // @ts-ignore
                _document.removeEventListener('start_playback', onStartPlaybackHandler);
            }
        }
    }, [_document, onStartPlaybackHandler]);
    useEffect(() => {
        if (_document) {
            // @ts-ignore
            _document.addEventListener('playback_ended', playbackEndedHandler);
            return () => {
                // @ts-ignore
                _document.removeEventListener('playback_ended', playbackEndedHandler);
            }
        }
    }, [_document, playbackEndedHandler]);
    useEffect(() => {
        if (_document) {
            // @ts-ignore
            _document.addEventListener('playback_paused', playbackPausedHandler);
            return () => {
                // @ts-ignore
                _document.removeEventListener('playback_paused', playbackPausedHandler);
            }
        }
    }, [_document, playbackPausedHandler]);
    useEffect(() => {
        if (_document) {
            // @ts-ignore
            _document.addEventListener('playback_resumed', playbackResumeHandler);
            return () => {
                // @ts-ignore
                _document.removeEventListener('playback_resumed', playbackResumeHandler);
            }
        }
    }, [_document, playbackResumeHandler]);
    const realVideo = useMemo<Video | undefined>(() => {
        if ('title' in video)
            return video;
        return findVideo(video.id);
    }, [video]);
    const containerClass = useMemo(() => {
        if (autoplay && fullscreen.current && containerRef.current) {
            if (callbackIndex === 0)
                return "fixed inset-0 w-[100vw] h-[100vh] sm:w-[calc(100vw-16rem)] sm:left-64 z-40 flex flex-col items-stretch justify-start bg-gray-100 dark:bg-gray-900 autoplay-video"
            else
                return "fixed inset-0 w-[100vw] h-[100vh] sm:w-[calc(100vw-16rem)] sm:left-64 z-40 flex flex-col items-stretch justify-start bg-gray-100 dark:bg-gray-900 autoplay-video video-hidden-bottom"
        }
        return "flex flex-col items-stretch justify-start";
    }, [autoplay, callbackIndex]);
    if (!realVideo)
        return null;

    return (
        <div
            className={containerClass} ref={containerRef}>
            <div
                className={"bg-" + colorCss + " p-2 flex flex-col items-center text-white justify-center relative" + (autoplay ? ' !items-start' : ' after:absolute after:right-0 after:top-0 after:border-8 after:border-gray-100 dark:after:border-gray-900 after:border-l-transparent after:border-b-transparent')}>
                {!!category && (
                    <span>{category}</span>
                )}
                <span>{realVideo.id.toUpperCase() + (autoplay ? (' ' + realVideo.title) : '')}</span>
            </div>
            <div
                className={"bg-white dark:bg-black relative max-h-full after:absolute after:top-0 after:left-[50%] after:translate-x-[-50%] after:border-8 after:border-l-[2rem] after:border-r-[2rem] after:border-transparent after:border-t-" + colorCss}>
                <video onEnded={onEnd}
                       poster={posterOfVideo(realVideo)}
                       controls={!(typeof callbackIndex === 'number' && autoplay)}
                       autoPlay={false} className="mx-auto max-h-full max-w-full w-auto h-auto"
                       ref={ref}>

                    <source src={pathOfVideoMp4(realVideo)} type="video/mp4"/>
                    <source src={pathOfVideoOgg(realVideo)} type="video/ogg"/>
                    <source src={pathOfVideoWebm(realVideo)} type="video/webm"/>
                </video>
            </div>
            {!autoplay && (<>
                    <div
                        className={"bg-white dark:bg-black text-black dark:text-white uppercase h-[2rem] 2xl:h-[3rem] flex items-center justify-center relative"}>
                        {realVideo.title}
                    </div>

                    <div
                        className={"bg-white dark:bg-black h-[2rem] 2xl:h-[3rem] flex items-center justify-between px-4"}>
                        <HeartIcon id={realVideo.id}/>
                        <PlanIcon id={realVideo.id} removeIcon={removeIcon}/>
                    </div>
                </>
            )}
        </div>
    );
}

export default VideoView;
