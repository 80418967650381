import React, {useContext} from 'react';
import {FavoriteContext} from "../../config/contexts";

const FavsNumber = () => {
    const {favs} = useContext(FavoriteContext);
    if (favs.length === 0)
        return null;
    return (
        <span
            className="inline-flex items-center justify-center w-3 h-3 p-3 ms-3 text-sm font-medium text-blue-800 bg-blue-100 rounded-full dark:bg-blue-900 dark:text-blue-300">{favs.length}</span>

    );
}

export default FavsNumber;