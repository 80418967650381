import React from "react";
import Wrapper from "../Wrapper";
import FavoritesView from "../client/FavoritesView";

export default function Favorites() {
    return (
        <Wrapper title="Lieblingsvideos">
            <FavoritesView/>
        </Wrapper>
    )
}
