import React, {PropsWithChildren, useMemo} from 'react';
import {Link} from "react-router-dom";
import {Category} from "../types/videos";
import {PlanViewProps} from "../types/PlanView";
import {catOfVideo, findVideo} from "../config/videos";
import EditPlanIcon from "./client/EditPlanIcon";
import ShareIcon from "./client/ShareIcon";
import AutoplayVideosView from "./client/AutoplayVideosView";

const defaultCat: Category = {
    name: '',
    videos: [],
    color: 'transparent',
    colorCss: 'transparent'
}

const PlanContainer: React.FC<PropsWithChildren<{
    customLink?: string;
    big: boolean,
    id: string
}>> = ({
           big,
           id,
           customLink,
           children
       }) => {
    if (big)
        return <div
            className="min-w-full max-w-full flex flex-col items-stretch justify-start my-4">
            {children}
        </div>
    return <Link to={customLink ?? ("/plan?pid=" + id)}
                 className="min-w-full max-w-full flex flex-col items-stretch justify-start my-4">
        {children}
    </Link>;
};

const PlanView: React.FC<PlanViewProps> = ({plan, customLink, big, hideIcons}) => {
    const planCategories = useMemo(() => {
        return plan.videos.reduce<{ cat: Category, length: number }[]>((prev, cur) => {
            let cat = catOfVideo(cur.id);
            if (!cat)
                cat = defaultCat;
            if (prev.length === 0)
                return [{
                    cat,
                    length: 1
                }];
            if (prev[prev.length - 1].cat.name === cat.name) {
                prev[prev.length - 1].length++;
            } else {
                prev.push({
                    cat, length: 1,
                });
            }
            return prev;
        }, []);
    }, [plan.videos]);
    const flexMax = useMemo(() => {
        return plan.videos.length;
    }, [plan.videos.length]);
    const getWidthClass = (length: number) => {
        if (flexMax >= 7) {
            return length === 1 ? 'h-[14.28%] lg:w-[14.28%]' : length === 2 ? 'h-[28.57%] lg:w-[28.57%]' : length === 3 ? 'h-[42.86%] lg:w-[42.86%]' : length === 4 ? 'h-[57.14%] lg:w-[57.14%]' : length === 5 ? 'h-[71.43%] lg:w-[71.43%]' : length === 6 ? 'h-[85.71%] lg:w-[85.71%]' : 'h-[100%] lg:w-[100%]';
        }
        if (flexMax >= 6) {
            return length === 1 ? 'h-[16.66%] lg:w-[16.66%]' : length === 2 ? 'h-[33.33%] lg:w-[33.33%]' : length === 3 ? 'h-[50%] lg:w-[50%]' : length === 4 ? 'h-[66.66%] lg:w-[66.66%]' : length === 5 ? 'h-[83.33%] lg:w-[83.33%]' : 'h-[100%] lg:w-[100%]';
        }
        if (flexMax >= 5) {
            return length === 1 ? 'h-[20%] lg:w-[20%]' : length === 2 ? 'h-[40%] lg:w-[40%]' : length === 3 ? 'h-[60%] lg:w-[60%]' : length === 4 ? 'h-[80%] lg:w-[80%]' : 'h-[100%] lg:w-[100%]';
        }
        if (flexMax >= 4) {
            return length === 1 ? 'h-[25%] lg:w-[25%]' : length === 2 ? 'h-[50%] lg:w-[50%]' : length === 3 ? 'h-[75%] lg:w-[75%]' : 'h-[100%] lg:w-[100%]';
        }
        if (flexMax >= 3) {
            return length === 1 ? 'h-[33.33%] lg:w-[33.33%]' : length === 2 ? 'h-[66.66%] lg:w-[66.66%]' : 'h-[100%] lg:w-[100%]';
        }
        return 'h-[100%] lg:w-[100%]';
    }
    let currentCat: Category = {
        name: '',
        videos: [],
        color: '',
        colorCss: '',
    };
    return (
        <PlanContainer big={!!big} id={plan.id} customLink={customLink}>
            <div className="flex flex-col items-center justify-center py-1 relative">
                <div className="absolute left-0">
                    {!hideIcons && (<EditPlanIcon plan={plan}/>)}
                </div>
                <h3 className="text-lg sm:text-xl font-bold text-gray-600">{plan.name}</h3>
                <div className="absolute right-0">
                    {!hideIcons && (<ShareIcon plan={plan}/>)}
                </div>
                <div
                    className="border-8 border-l-transparent border-b-transparent border-r-transparent border-t-gray-600"/>
            </div>
            <div className="bg-gray-600 flex flex-col items-center justify-center py-2">
                {plan.desc && (<span className="text-white">{plan.desc}</span>)}
            </div>
            {big ? (
                <AutoplayVideosView videos={plan.videos} removeIcon={plan.id}/>
            ) : (
                <div className="flex flex-row lg:flex-col mx-[-0.125rem]">
                    <div
                        className="hidden lg:flex w-[100%] flex-col lg:flex-row text-center">
                        {planCategories.map(c => (
                            <div key={c.cat.name + "-" + "cat"}
                                 className={"text-sm sm:text-[1rem] flex flex-col mx-0.5 my-0.5 lg:my-0 items-center justify-center text-center py-1 text-white w-[100%] lg:h-auto bg-" + c.cat.colorCss + " " + getWidthClass(c.length)}>
                                {c.cat.name}
                            </div>
                        ))}
                    </div>
                    <div
                        className="flex w-[100%] max-w-[100%] flex-col lg:flex-row items-stretch justify-start text-center">
                        {plan.videos.map((v) => {
                            const cat = catOfVideo(v.id);
                            const inner = (<div key={"plan-" + plan.id + "-" + v.id}
                                                className="flex-1 mx-0.5 my-0.5 lg:my-0 relative px-4 flex flex-col items-center justify-center bg-gray-200">
                                <span>{v.id}</span>
                                <span
                                    className="font-bold my-2 lg:text-sm xl:text-[1rem]">{findVideo(v.id)?.title}</span>

                                <div
                                    className={"border-l-" + cat?.colorCss + " lg:border-l-gray-100 lg:dark:border-l-gray-900 border-8 border-t-transparent border-b-transparent border-r-transparent absolute left-0"}/>

                            </div>);
                            if (!cat || currentCat.name === cat.name) {
                                return inner;
                            }
                            currentCat = cat;
                            return (
                                <React.Fragment key={"plan-" + plan.id + "-" + v.id + "-cat"}>
                                    <div
                                        className={"mx-0.5 mt-1 mb-0.5 py-2 lg:hidden bg-" + cat?.colorCss}>{cat?.name}</div>
                                    {inner}
                                </React.Fragment>
                            );
                        })}
                    </div>
                </div>
            )}
        </PlanContainer>
    );
}

export default PlanView;