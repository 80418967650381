import React, {useCallback, useMemo} from 'react';
import {SinglePlanLinkProps} from "../../types/SinglePlanLink";
import {Link, useSearchParams} from "react-router-dom";
import {includesId} from "../../config/utils";

const SinglePlanLink: React.FC<SinglePlanLinkProps> = ({
                                                           plan,
                                                           addToPlan,
                                                           removeFromPlan,
                                                           open,
                                                           highligt
                                                       }) => {
    const [searchParams] = useSearchParams();
    const isActiveInner = useMemo(() => {
        const param = searchParams.get('pid');
        if (!param) {
            return false;
        }
        return param === plan.id;
    }, [searchParams, plan.id]);

    const onClick = useCallback(() => {
        if (typeof open === 'string') {
            if (includesId(plan.videos, open))
                removeFromPlan(plan.id);
            else
                addToPlan(plan.id);
        }
    }, [addToPlan, open, plan.id, plan.videos, removeFromPlan]);
    return (
        <li
            className={'transition duration-1000 bg-gray-50 dark:bg-gray-800 ' + (highligt ? 'bg-gray-300 dark:bg-gray-500' : '')}>
            {typeof open === 'string' ? (
                <button onClick={onClick}
                        className={"flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group" + (isActiveInner ? ' bg-gray-100 dark:bg-gray-700' : '')}>
                    <svg
                        className={"flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" + (isActiveInner ? ' text-gray-900 dark:text-white' : '')}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor" viewBox="0 0 24 24">
                        <path
                            d={includesId(plan.videos, open)
                                ? 'm12.002 2.005c5.518 0 9.998 4.48 9.998 9.997 0 5.518-4.48 9.998-9.998 9.998-5.517 0-9.997-4.48-9.997-9.998 0-5.517 4.48-9.997 9.997-9.997zm0 1.5c-4.69 0-8.497 3.807-8.497 8.497s3.807 8.498 8.497 8.498 8.498-3.808 8.498-8.498-3.808-8.497-8.498-8.497zm4.253 7.75h-8.5c-.414 0-.75.336-.75.75s.336.75.75.75h8.5c.414 0 .75-.336.75-.75s-.336-.75-.75-.75z'
                                : 'm12.002 2c5.518 0 9.998 4.48 9.998 9.998 0 5.517-4.48 9.997-9.998 9.997-5.517 0-9.997-4.48-9.997-9.997 0-5.518 4.48-9.998 9.997-9.998zm0 1.5c-4.69 0-8.497 3.808-8.497 8.498s3.807 8.497 8.497 8.497 8.498-3.807 8.498-8.497-3.808-8.498-8.498-8.498zm-.747 7.75h-3.5c-.414 0-.75.336-.75.75s.336.75.75.75h3.5v3.5c0 .414.336.75.75.75s.75-.336.75-.75v-3.5h3.5c.414 0 .75-.336.75-.75s-.336-.75-.75-.75h-3.5v-3.5c0-.414-.336-.75-.75-.75s-.75.336-.75.75z'}/>
                    </svg>
                    <span className="flex-1 ms-3 whitespace-nowrap">{plan.name}</span>
                </button>
            ) : (
                <Link to={"/plan?pid=" + plan.id} type="button"
                      className={"flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group" + (isActiveInner ? ' bg-gray-100 dark:bg-gray-700' : '')}>
                    <span className="flex-1 ms-3 whitespace-nowrap">{plan.name}</span>
                    <span
                        className="inline-flex items-center justify-center w-3 h-3 p-3 ms-3 text-sm font-medium text-blue-800 bg-blue-100 rounded-full dark:bg-blue-900 dark:text-blue-300">{plan.videos.length}</span>

                </Link>)}
        </li>
    );
}

export default SinglePlanLink;