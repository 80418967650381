class PlayNextEvent extends Event {
    public index: number;

    constructor(type: 'play_next', eventInitDict: EventInit, index: number) {
        super(type, eventInitDict);
        this.index = index;
    }
}

class StartPlaybackEvent extends Event {
    public waitDuration: number;
    public fullscreen: boolean;
    public repeat: number;

    constructor(type: "start_playback", eventInitDict: EventInit, options: {
        fullscreen: boolean,
        waitDuration: number,
        repeat: number,
    }) {
        super(type, eventInitDict);
        this.repeat = options.repeat;
        this.waitDuration = options.waitDuration;
        this.fullscreen = options.fullscreen;
    }
}

class PlaybackEndedEvent extends Event {

    constructor(type: "playback_ended", eventInitDict: EventInit) {
        super(type, eventInitDict);
    }
}

class PlaybackPausedEvent extends Event {

    constructor(type: "playback_paused", eventInitDict: EventInit) {
        super(type, eventInitDict);
    }
}

class PlaybackResumedEvent extends Event {

    constructor(type: "playback_resumed", eventInitDict: EventInit) {
        super(type, eventInitDict);
    }
}


export {
    PlayNextEvent,
    StartPlaybackEvent,
    PlaybackEndedEvent,
    PlaybackResumedEvent,
    PlaybackPausedEvent
};