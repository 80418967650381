import React, {useContext, useEffect, useMemo} from 'react';
import {PlansViewProps} from "../../types/PlansView";
import {PlanContext} from "../../config/contexts";
import {useSearchParams} from "react-router-dom";
import {Plan} from "../../types/plan";
import {storedPlans} from "../../config/plans";
import {includesId} from "../../config/utils";
import PlanView from "../PlanView";

const PlansView: React.FC<PlansViewProps> = ({sharedPlan}) => {
    const {plans, setPlans} = useContext(PlanContext);
    const params = useSearchParams();
    const plan = useMemo(() => {
        let p: Plan | undefined;
        const pid = params[0].get('pid');
        const share = params[0].get('share');
        if (pid) {
            p = plans.concat(storedPlans).find(p => p.id === pid);
        } else if (share) {
            p = sharedPlan;
        }
        return p;
    }, [params, plans, sharedPlan]);
    useEffect(() => {
        if (sharedPlan) {
            if (!includesId(plans, sharedPlan.id)) {
                setPlans(plans.concat([sharedPlan]));
            }
        }
    }, [sharedPlan, setPlans, plans]);
    if (plan) {
        return <PlanView plan={plan} big/>
    }
    return (
        <div
            className="min-w-full max-w-full flex flex-col">
            {plans.map((p) => (
                <PlanView plan={p} key={p.id}/>
            ))}
            {plans.length === 0 && (
                <span>Keine Trainingspläne gespeichert</span>
            )}
        </div>
    );
}

export default PlansView;