import React, {PropsWithChildren, useCallback, useEffect, useMemo, useState} from 'react';
import {FAVORITES} from "../../config/constants";
import {FavContextType, FavoriteContext} from "../../config/contexts";


const FavoriteProvider: React.FC<PropsWithChildren> = ({children}) => {
    const [favs, setFavs] = useState<string[]>([]);
    const setFavsCont = useCallback((newFavs: string[]) => {
        if (typeof localStorage !== 'undefined')
            localStorage.setItem(FAVORITES, JSON.stringify(newFavs));
        setFavs(newFavs);

    }, []);
    const contextVal = useMemo<FavContextType>(() => {
        return {
            favs,
            setFavs: setFavsCont
        }
    }, [favs, setFavsCont]);
    useEffect(() => {
        const s = typeof localStorage !== 'undefined' ? localStorage.getItem(FAVORITES) : null;
        if (s)
            setFavs(JSON.parse(s));
    }, [])
    return (
        <FavoriteContext.Provider value={contextVal}>
            {children}
        </FavoriteContext.Provider>
    );
}

export default FavoriteProvider;