export const MIN_IN_MS = 60000;
export const HOUR_IN_MS = MIN_IN_MS * 60;
export const DAY_IN_MS = HOUR_IN_MS * 24;
export const WEEK_IN_MS = DAY_IN_MS * 7;
export const MONTH_IN_MS = DAY_IN_MS * 31;
export const YEAR_IN_MS = DAY_IN_MS * 365;


export const COOKIE_CONSENT = "aad8zias";
export const MY_PLANS = "igdsf_v1";
export const FAVORITES = "439gt_v1";

export const FULLSCREEN_SETTING = "play_fullscreen";
export const WAIT_DURATION_SETTING = "wait_duration_between_vids";
