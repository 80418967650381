import React from "react";
import {Plan} from "../types/plan";

export declare type FavContextType = {
    favs: string[];
    setFavs: (favs: string[]) => void;
}

export const FavoriteContext = React.createContext<FavContextType>({
    favs: [],
    setFavs: (favs) => {
        //nothing
    }
});
export declare type PlanContextType = {
    plans: Plan[];
    setPlans: (plans: Plan[]) => void;
    setOpen: (id: string | boolean) => void;
    open: boolean | string;
}

export const PlanContext = React.createContext<PlanContextType>({
    plans: [],
    setPlans: (plans) => {
        //nothing
    },
    open: false,
    setOpen: (id) => {
        // nothing
    },
});