import React from "react";
import Wrapper from "../Wrapper";
import VideoView from "../VideoView";
import {MW} from "../../config/videos";


export default function MobilisationWirbel() {
    return (
        <Wrapper title="Mobilisation Wirbelsäule" titleColorClass="text-ftsred">
            <div
                className="min-w-full grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4">
                {MW.videos.map(v => (
                    <VideoView video={v} key={v.id} color={MW.color}
                               colorCss={MW.colorCss}/>
                ))}
            </div>
        </Wrapper>
    )
}
