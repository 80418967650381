import React from "react";
import Wrapper from "../Wrapper";
import {MS} from "../../config/videos";
import VideoView from "../VideoView";

export default function MobilisationSchulter() {
    return (
        <Wrapper title="Mobilisation Schulter" titleColorClass="text-ftsblue">
            <div
                className="min-w-full grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4">
                {MS.videos.map(v => (
                    <VideoView video={v} key={v.id} color={MS.color}
                               colorCss={MS.colorCss}/>
                ))}
            </div>
        </Wrapper>
    )
}
