import React, {useCallback, useState} from "react";
import {Link} from "react-router-dom";
import {HomeNavProps} from "../../types/HomeNav";

const HomeNav: React.FC<HomeNavProps> = ({links, title, extra}) => {
    const [open, setOpen] = useState(false);
    const toggleOpen = useCallback(() => {
        setOpen(o => !o);
    }, []);
    return (
        <li className={"pl-6 xl:pl-10 relative mt-5 xl:mt-8 2xl:mt-10 z-10 max-w-fit items-start justify-center before:absolute before:border-transparent before:border-l-accent before:border-[1rem] xl:before:border-[1.5rem] before:left-0 before:top-[50%] before:translate-y-[-50%]"}>
            <button onClick={toggleOpen}>
                <h4 className={"text-lg font-semibold uppercase xl:text-2xl 2xl:text-4xl text-left"}>{title}</h4>
            </button>
            {open && links.map((link) => (
                <Link to={link.href} key={link.href}
                      className={"text-sm xl:text-xl 2xl:text-2xl my-1 block"}>{link.title}</Link>
            ))}
            {!!extra && (<span
                    className={"block text-sm xl:text-xl 2xl:text-2xl"}>{extra}</span>

            )}
        </li>
    )
}

export default HomeNav;