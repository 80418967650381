import {SupCategory, Video} from "../types/videos";


export const supCategories: SupCategory[] = [
    {
        name: 'Mobilisation',
        categories: [
            {
                name: 'Schulter',
                color: '#43b1e6',
                colorCss: 'ftsblue',
                videos: [
                    {
                        id: 'MS 01',
                        title: 'Armzug stehend',
                        photos: 2
                    },
                    {
                        id: 'MS 02',
                        title: 'Armzug sitzend',
                        photos: 2
                    },
                    {
                        id: 'MS 03',
                        title: 'Flaschenzug',
                        photos: 2
                    },
                    {
                        id: 'MS 04',
                        title: 'hoher Armzug',
                        photos: 2
                    },
                    {
                        id: 'MS 05',
                        title: 'Tisch',
                        photos: 2
                    },
                    {
                        id: 'MS 06',
                        title: 'vorgebeugter Armschlag',
                        photos: 2
                    },
                    {
                        id: 'MS 07',
                        title: 'Brustöffner',
                        photos: 2
                    },
                    {
                        id: 'MS 08',
                        title: 'hoher Brustöffner',
                        photos: 2
                    },
                    {
                        id: 'MS 09',
                        title: 'tiefer Brustöffner',
                        photos: 2
                    },
                    {
                        id: 'MS 10',
                        title: 'Schulterblattmobilisation',
                        photos: 2
                    },
                    {
                        id: 'MS 11',
                        title: 'Außenrotation',
                        photos: 2
                    },
                    {
                        id: 'MS 12',
                        title: 'hohe Außenrotation',
                        photos: 2
                    },
                    {
                        id: 'MS 13',
                        title: 'Armzug hinten',
                        photos: 2
                    },
                    {
                        id: 'MS 14',
                        title: 'Windmühle',
                        photos: 2
                    },
                    {
                        id: 'MS 15',
                        title: 'Seitlicher Armzug - stehend',
                        photos: 2
                    },
                    {
                        id: 'MS 16',
                        title: 'Seitlicher Armzug - sitzend',
                        photos: 2
                    },
                    {
                        id: 'MS 17',
                        title: 'Endgradige Abduktion',
                        photos: 2
                    },
                    {
                        id: 'MS 18',
                        title: 'Armadduktion',
                        photos: 2
                    },
                ]
            },
            {
                name: 'Wirbelsäule',
                color: '#962822',
                colorCss: 'ftsred',
                videos: [

                    {
                        id: 'MW 01',
                        title: 'hängendes V',
                        thumbnail: 'smw01.jpg',
                        photos: 2
                    },
                    {
                        id: 'MW 02',
                        title: 'Körperöffner',
                        photos: 2
                    },
                    {
                        id: 'MW 03',
                        title: 'Verbeugung',
                        thumbnail: 'smw03.jpg',
                        photos: 2
                    },
                    {
                        id: 'MW 04',
                        title: 'tiefe Hocke',
                        thumbnail: 'smw04.jpg',
                        photos: 2
                    },
                    {
                        id: 'MW 05',
                        title: 'Korkenzieher',
                        photos: 2
                    },
                    {
                        id: 'MW 06',
                        title: 'Windmühle vorgebeugt',
                        photos: 2
                    },
                    {
                        id: 'MW 07',
                        title: 'Oberkörperrotation',
                        photos: 2
                    },
                    {
                        id: 'MW 08',
                        title: 'gedrehtes V',
                        photos: 2
                    },
                    {
                        id: 'MW 09',
                        title: 'Rotationsseilzug',
                        photos: 2
                    },
                    {
                        id: 'MW 10',
                        title: 'Tisch mit seitlichem Zug',
                        photos: 2
                    },
                    {
                        id: 'MW 11',
                        title: 'kniender Halbmond',
                        photos: 2
                    },
                    {
                        id: 'MW 12',
                        title: 'Halbmond',
                        photos: 2
                    },

                ]
            },
        ]
    },
    {
        name: 'Kräftigung',
        categories: [

            {
                name: 'Körpergewicht',
                color: '#8e6491',
                colorCss: 'ftspurple',
                videos: [
                    {
                        id: 'KK 01',
                        title: 'Rudern',
                        photos: 2
                    },
                    {
                        id: 'KK 02',
                        title: 'Brustpresse',
                        photos: 2
                    },
                    {
                        id: 'KK 03',
                        title: 'Armbeugen',
                        photos: 2
                    },
                    {
                        id: 'KK 04',
                        title: 'Trizepspresse',
                        photos: 2
                    },
                    {
                        id: 'KK 05',
                        title: 'Fly',
                        photos: 2
                    },
                    {
                        id: 'KK 06',
                        title: 'Umgekehrter Fly',
                        photos: 2
                    },
                    {
                        id: 'KK 07',
                        title: 'Außenrotation',
                        photos: 2
                    },
                    {
                        id: 'KK 08',
                        title: 'Innenrotation',
                        photos: 2
                    },
                    {
                        id: 'KK 09',
                        title: 'Kniebeuge',
                        photos: 2
                    },
                    {
                        id: 'KK 10',
                        title: 'Ausfallschritt & Fly',
                        photos: 2
                    },
                    {
                        id: 'KK 11',
                        title: 'Einbeinige Kniebeuge',
                        photos: 2
                    },
                    {
                        id: 'KK 12',
                        title: 'Wadenheben',
                        photos: 2
                    },
                ]
            },
            {
                name: 'Widerstandseil',
                color: '#8db38f',
                colorCss: 'ftsgreen',
                videos: [

                    {
                        id: 'KW 01',
                        title: 'Rudern',
                        photos: 2
                    },
                    {
                        id: 'KW 02',
                        title: 'Fly',
                        photos: 2
                    },
                    {
                        id: 'KW 03',
                        title: 'Umgekehrter diagonaler Fly',
                        photos: 2
                    },
                    {
                        id: 'KW 04',
                        title: 'vorgebeugter Überzug',
                        photos: 2
                    },
                    {
                        id: 'KW 05',
                        title: 'hohe Außenrotation',
                        photos: 2
                    },
                    {
                        id: 'KW 06',
                        title: 'hohe Innenrotation',
                        photos: 2
                    },
                    {
                        id: 'KW 07',
                        title: 'Schulter Seitheben',
                        photos: 2
                    },
                    {
                        id: 'KW 08',
                        title: 'Schulterdrücken',
                        photos: 2
                    },
                    {
                        id: 'KW 09',
                        title: 'Rumpfrotation',
                        photos: 2
                    },
                    {
                        id: 'KW 10',
                        title: 'Antirotationspresse',
                        photos: 2
                    },
                    {
                        id: 'KW 11',
                        title: 'Crunch in Stehen',
                        photos: 2
                    },
                    {
                        id: 'KW 12',
                        title: 'Rumprotation nach hinten',
                        photos: 2
                    },
                    {
                        id: 'VAR KW 13',
                        title: 'Armbeugen',
                        photos: 2
                    },
                    {
                        id: 'VAR KW 14',
                        title: 'Trizepspresse',
                        photos: 2
                    },
                    {
                        id: 'VAR KW 15',
                        title: 'Umgekehrter Fly',
                        photos: 2
                    },
                    {
                        id: 'VAR KW 16',
                        title: 'Brustpresse',
                        photos: 2
                    },
                    {
                        id: 'VAR KW 17',
                        title: 'Schulterabduktion',
                        photos: 2
                    },
                    {
                        id: 'VAR KW 17a',
                        title: 'Schulterabduktion unterer Bereich',
                        photos: 2
                    }
                ]
            },
            {
                name: 'Seilzug',
                color: '#eeb20f',
                colorCss: 'ftsyellow',
                videos: [
                    {
                        id: 'KS 01',
                        title: 'einarmiges Rudern',
                        photos: 2
                    },
                    {
                        id: 'KS 02',
                        title: 'einarmige Brustpresse',
                        photos: 2
                    },
                    {
                        id: 'KS 03',
                        title: 'hohe Außenrotation',
                        photos: 2
                    },
                    {
                        id: 'KS 04',
                        title: 'hohe Innenrotation',
                        photos: 2
                    },
                    {
                        id: 'KS 05',
                        title: 'Schulteradduktion',
                        photos: 2
                    },
                    {
                        id: 'KS 06',
                        title: 'Überzug',
                        photos: 2
                    },
                    {
                        id: 'KS 07',
                        title: 'Umgekehrter einarmiger Fly',
                        photos: 2
                    },
                    {
                        id: 'KS 08',
                        title: 'einarmige Trizepspresse',
                        photos: 2
                    },
                    {
                        id: 'KS 09',
                        title: 'Crunch im Stehen',
                        photos: 2
                    },
                    {
                        id: 'KS 10',
                        title: 'Wood Chop',
                        photos: 2
                    },
                    {
                        id: 'KS 11',
                        title: 'einarmige Rumpfrotation',
                        photos: 2
                    },
                    {
                        id: 'KS 12',
                        title: 'Antirotationspresse',
                        photos: 2,
                    },
                    {
                        id: 'VAR KS 13',
                        title: 'unterstützter Sit Up',
                        photos: 2,
                    },
                    {
                        id: 'VAR KS 14',
                        title: 'Innenrotation',
                        photos: 2,
                    },
                    {
                        id: 'VAR KS 15',
                        title: 'Außenrotation',
                        photos: 2,
                    },
                    {
                        id: 'VAR KS 16',
                        title: 'Rotation rückwärts',
                        photos: 2,
                    }
                ]
            },
        ]
    }
]
export const MOBILITATION = supCategories[0];
export const KRAEFTIGUNG = supCategories[1];
export const MS = MOBILITATION.categories[0];
export const MW = MOBILITATION.categories[1]
export const KK = KRAEFTIGUNG.categories[0];
export const KW = KRAEFTIGUNG.categories[1];
export const KS = KRAEFTIGUNG.categories[2];


export function pathOfVideoMp4(video: Video): string {
    return "/assets/videos/" + video.id.toLowerCase().replaceAll(" ", "") + ".mp4";
}


export function pathOfVideoOgg(video: Video): string {
    return "/assets/videos/" + video.id.toLowerCase().replaceAll(" ", "") + ".ogg";
}


export function pathOfVideoWebm(video: Video): string {
    return "/assets/videos/" + video.id.toLowerCase().replaceAll(" ", "") + ".webm";
}

export function posterOfVideo(video: Video): string {
    return "/assets/poster/" + video.id.toLowerCase().replaceAll(" ", "") + ".webp";
}

export function gifOfVideo(video: Video): string {
    return "/assets/gif/" + video.id.toLowerCase().replaceAll(" ", "") + ".gif";
}


export function generateNewFavCookieString(old: string[], id: string, add: boolean): string {
    const without = old.filter(o => o !== id);
    if (add) {
        return JSON.stringify(without.concat([id]));
    }
    return JSON.stringify(without)
}

export function findVideo(id: string) {
    if (id.includes("MS"))
        return MS.videos.find(v => v.id === id);
    if (id.includes("MW"))
        return MW.videos.find(v => v.id === id);
    if (id.includes("KK"))
        return KK.videos.find(v => v.id === id);
    if (id.includes("KW"))
        return KW.videos.find(v => v.id === id);
    if (id.includes("KS"))
        return KS.videos.find(v => v.id === id);
    return supCategories.reduce<Video | undefined>((prev, cur) => {
        const v = cur.categories.reduce<Video | undefined>((prevC, curC) => {
            const c = curC.videos.find(vid => vid.id === id);
            if (c)
                return c;
            return prevC;
        }, undefined);
        if (v)
            return v;
        return prev;
    }, undefined);
}

export function catOfVideo(id: string) {
    if (id.includes("MS"))
        return MS;
    if (id.includes("MW"))
        return MW;
    if (id.includes("KK"))
        return KK;
    if (id.includes("KW"))
        return KW;
    if (id.includes("KS"))
        return KS;
}
