import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {HeaderLinkProps} from "../../types/HeaderLink";
import {PlanContext} from "../../config/contexts";
import {Plan} from "../../types/plan";
import {generatePW} from "../../config/utils";
import SinglePlanLink from "./SinglePlanLink";


const PlanLink: React.FC<HeaderLinkProps> = ({
                                                 pathname,
                                                 icon,
                                                 text,
                                             }) => {


    const {plans, open, setOpen, setPlans} = useContext(PlanContext);
    const [highlightSome, setHighlightSome] = useState(-1);
    const [_document, set_document] = useState<Document>();
    useEffect(() => {
        if (typeof window !== 'undefined') {
            set_document(window.document);
        }
    }, []);
    const addToPlan = useCallback((planId: string) => {
        if (typeof open === 'string') {
            const newPlans = plans.map<Plan>((p, idx) => {
                if (p.id === planId) {
                    setHighlightSome(idx);
                    setTimeout(() => {
                        setHighlightSome(-1);
                    }, 2000);
                    return {
                        ...p,
                        videos: p.videos.concat({id: open})
                    }
                }
                return p;
            })
            setPlans(newPlans);
        }
        setOpen(true);
    }, [open, plans, setOpen, setPlans]);
    const removeFromPlan = useCallback((planId: string) => {
        if (typeof open === 'string') {
            const newPlans = plans.map((p, idx) => {
                if (p.id === planId) {
                    setHighlightSome(idx);
                    setTimeout(() => {
                        setHighlightSome(-1);
                    }, 2000);
                    return {
                        ...p,
                        ids: p.videos.filter((elem) => elem.id !== open)
                    }
                }
                return p;
            });
            setPlans(newPlans)
        }
        setOpen(true);
    }, [open, plans, setOpen, setPlans]);
    const createPlan = useCallback(() => {
        if (_document) {
            if (typeof open === "string") {
                const inp = _document.getElementById("new-plan");
                const newPlans = plans.concat([{
                    id: generatePW(),
                    videos: [{id: open}],
                    name: inp ? (inp as HTMLInputElement).value ?? "" : '',
                    desc: ""
                }]);
                setPlans(newPlans);
                setTimeout(() => {
                    setHighlightSome(plans.length);
                    setTimeout(() => {
                        setHighlightSome(-1);
                    }, 2000);
                }, 100);
            }
            setOpen(false);
        }
    }, [_document, open, plans, setOpen, setPlans]);
    const togglePlans = useCallback(() => {

        setOpen(!open);

    }, [open, setOpen]);
    const cancelAdd = useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    const prevLength = useRef(plans.length);
    useEffect(() => {
        if (plans.length > prevLength.current) {
            setHighlightSome(plans.length - 1);
            setTimeout(() => {
                setHighlightSome(-1);
            }, 2000);
        }
        if (plans.length !== prevLength.current) {
            prevLength.current = plans.length;
        }
    }, [plans.length]);

    const path = window.location.pathname;
    const isActive = useMemo(() => {
        return path.startsWith(pathname);
    }, [path, pathname]);

    return (
        <li>
            <button type="button" onClick={togglePlans}
                    className={"flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700" + (isActive ? ' bg-gray-100 dark:bg-gray-700' : '')}>
                {icon && (<svg
                    className={"flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white" + (isActive ? ' text-gray-900 dark:text-white' : '')}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor" viewBox="0 0 24 24">
                    {icon.map(i => (
                        <path key={i}
                              d={i}/>)
                    )}
                </svg>)}
                <span
                    className="flex-1 ms-3 text-left rtl:text-right whitespace-nowrap">{text}</span>
                {plans.length !== 0 && (<span
                    className="inline-flex items-center justify-center w-3 h-3 p-3 ms-3 text-sm font-medium text-blue-800 bg-blue-100 rounded-full dark:bg-blue-900 dark:text-blue-300">{plans.length}</span>)}

            </button>
            <ul
                className={(open || isActive ? '' : 'hidden ') + "py-2 space-y-2"}>
                {plans.map((p, idx) => (
                    <SinglePlanLink highligt={idx === highlightSome} addToPlan={addToPlan}
                                    removeFromPlan={removeFromPlan} plan={p} key={p.id}
                                    open={open}/>
                ))}
                {typeof open === 'string' && (
                    <>
                        <li>
                            <div
                                className={"flex flex-col items-stretch p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group" + (isActive ? ' bg-gray-100 dark:bg-gray-700' : '')}>
                                <span
                                    className="flex-1 ms-3 whitespace-nowrap font-bold">Neuen Plan erstellen</span>
                                <input type="text" id="new-plan" placeholder="Name"
                                       className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"/>
                                <button onClick={createPlan}
                                        className="mt-1 font-bold border border-accent py-2 px-4 rounded-lg">Hinzufügen
                                </button>
                                <button type="button"
                                        onClick={cancelAdd}
                                        className="mt-1 font-bold border border-secondary py-2 px-4 rounded-lg">Abbrechen
                                </button>
                            </div>
                        </li>
                    </>
                )}
            </ul>
        </li>
    );

}

export default PlanLink;