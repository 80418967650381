import React, {PropsWithChildren, useCallback, useEffect, useMemo, useState} from 'react';
import {Plan} from "../../types/plan";
import {MY_PLANS} from "../../config/constants";
import {PlanContext, PlanContextType} from "../../config/contexts";


const PlanProvider: React.FC<PropsWithChildren> = ({children}) => {
    const [plans, setPlans] = useState<Plan[]>([]);
    const [open, setOpen] = useState<boolean | string>(window.location.pathname === "/plan");
    const setPlansCont = useCallback((newPlans: Plan[]) => {
        setPlans(newPlans);
        if (typeof localStorage !== 'undefined')
            localStorage.setItem(MY_PLANS, JSON.stringify(newPlans));
    }, []);
    const contextVal = useMemo<PlanContextType>(() => {
        return {
            plans,
            setPlans: setPlansCont,
            open,
            setOpen
        }
    }, [open, plans, setPlansCont])
    useEffect(() => {
        const s = typeof localStorage !== 'undefined' ? localStorage.getItem(MY_PLANS) : null;
        if (s) {
            setPlans(JSON.parse(s) as Plan[]);
        }
    }, []);
    return (
        <PlanContext.Provider value={contextVal}>
            {children}
        </PlanContext.Provider>
    );
}

export default PlanProvider;