/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, {useEffect, useMemo, useState} from "react";
import {Plan as PlanObject} from "../../types/plan";
import Wrapper from "../Wrapper";
import HasShared from "../client/HasShared";
import PlansView from "../client/PlansView";
import {useSearchParams} from "react-router-dom";


const Plan: React.FC = () => {
    const params = useSearchParams();
    const [sharedPlan, setSharedPlan] = useState<PlanObject>()
    const [hasShared, setHasShared] = useState<PlanObject>()
    const isSharing = useMemo(() => {
        return params[0].get('shared');
    }, [params[0]]);
    const shared = useMemo(() => {
        return params[0].get('share');
    }, [params[0]]);
    useEffect(() => {

        if (isSharing) {
            fetch("/files/" + isSharing + ".json", {
                method: 'GET',
                headers: {'Content-Type': 'application/json'}
            }).then(res => {
                res.json().then(setHasShared);
            });
        }
        if (shared) {
            fetch("/files/" + shared + ".json", {
                method: 'GET',
                headers: {'Content-Type': 'application/json'}
            }).then(res => {
                res.json().then(setSharedPlan);
            });
        }
    }, [shared, isSharing]);

    return (
        <Wrapper
            title={sharedPlan ? sharedPlan.name : hasShared ? 'Teilen' : 'Meine Trainingspläne'}>
            {hasShared ? (
                <HasShared plan={hasShared}/>
            ) : sharedPlan ? (
                <PlansView sharedPlan={sharedPlan}/>
            ) : <PlansView sharedPlan={undefined}/>}
        </Wrapper>
    )
}
export default Plan;