import {Plan} from "../types/plan";

export const storedPlans: Plan[] = [
    {
        id: 'IMPINGEMENT‐SYNDROM',
        name: 'Impingement Syndrom',
        desc: "Ziel: Schulterdachentlastung und Schultergürtelretraktionstraining",
        videos: [{
            id: 'KS 01',
            desc: 'Schulterblatt bewusst nach unten und Richtung Mitte ziehen'
        }, {
            id: 'KS 05',
            desc: 'Start aus ca. 60° Abduktion'
        }, {
            id: 'KS 07',
            desc: 'Arme unter 60° Abduktion'
        }, {
            id: 'KW 07',
            desc: 'vorsichtig schmerzfrei bis ca. 60°'
        }, {
            id: 'KK 03',
            desc: 'Oberarm ist am Körper fixiert'
        }, {
            id: 'KK 07',
            desc: 'Oberarm ist am Körper fixiert'
        }],
    },
    {
        id: 'SCHULTERLUXATION',
        name: 'Schulterluxation',
        desc: "Ziel: Kräftigung der gelenkstabilisierenden Muskulatur",
        videos: [{
            id: 'KK 03',
            desc: 'Oberarm ist am Körper fixiert'
        }, {
            id: 'KK 07',
            desc: 'Oberarm ist am Körper fixiert'
        }, {
            id: 'KK 08',
            desc: 'Oberarm ist am Körper fixiert'
        }, {
            id: 'KW 01',
            desc: 'Schulterblatt bewusst nach unten und Richtung Mitte ziehen'
        }, {
            id: 'KW 04',
            desc: 'Start unter 90° Flexion'
        }, {
            id: 'KW 07',
            desc: 'bis 90° Abduktion'
        }],
    },
    {
        id: 'SCHULTERINSTABILITÄT',
        name: 'Schulterinstabilität',
        desc: "Ziel: Kräftigung der Rotatorenmanschettenmuskulatur",
        videos:
            [{
                id: 'KK 03',
                desc: 'Oberarm ist am Körper fixiert'
            }, {
                id: 'KK 07',
                desc: 'Oberarm ist am Körper fixiert'
            }, {
                id: 'KK 08',
                desc: 'Oberarm ist am Körper fixiert'
            }, {
                id: 'KW 02',
            }, {
                id: 'KW 05',
            }, {
                id: 'KW 06',
            }],
    },
    {
        id: 'CORE STABILITY',
        name: 'Core Stability',
        desc: "Ziel: Kräftigung der Rumpfmuskulatur und Haltungsschulung",
        videos:
            [{
                id: 'KW 01',
            }, {
                id: 'KW 03',
            }, {
                id: 'KW 09',
            }, {
                id: 'KW 10',
            }, {
                id: 'KW 11',
            }, {
                id: 'KW 12',
            }],
    },
    {
        id: 'FULL BODY WORKOUT',
        name: 'Full Body Workout',
        desc: "Ziel: allgemeine Kräftigung Oberkörper, Rumpf und Beine",
        videos:
            [{
                id: 'KS 01',
            }, {
                id: 'KS 02',
            }, {
                id: 'KK 09',
            }, {
                id: 'KK 10',
            }, {
                id: 'KW 10',
            }, {
                id: 'KW 11',
            }],
    },
    {
        id: 'OFFICE WORKOUT',
        name: 'Office Workout',
        desc: "Ziel: allgemeine Kräftigung und Verbesserung der Körperhaltung",
        videos:
            [{
                id: 'KW 01',
            }, {
                id: 'KW 03',
            }, {
                id: 'KW 10',
            }, {
                id: 'KW 12',
            }, {
                id: 'KK 10',
            }, {
                id: 'KK 11',
            }],
    },
    {
        id: 'SCHULTERMOBILISATION',
        name: 'Schultermobilisation',
        desc: "Ziel: Wiedererlangen der Beweglichkeit in alle Bewegungsrichtungen",
        videos:
            [{
                id: 'MS 04',
            }, {
                id: 'MS 09',
            }, {
                id: 'MS 12',
            }, {
                id: 'MS 13',
            }, {
                id: 'MS 15',
            }, {
                id: 'MS 18',
            }],
    },
    {
        id: 'KOERPERHALTUNG',
        name: 'Aufrechte Körperhaltung',
        desc: "Ziel: Verbesserung der Körperhaltung durch Brustöffnung und Schultermobilisation",
        videos:
            [{
                id: 'MS 06',
            }, {
                id: 'MS 07',
            }, {
                id: 'MS 09',
            }, {
                id: 'MS 10',
            }, {
                id: 'MS 12',
            }, {
                id: 'MS 17',
            }],
    },
    {
        id: 'OFFICEMOBILITY',
        name: 'Office Mobility',
        desc: "Ziel: Verspannungen lösen und Körperhaltung verbessern",
        videos:
            [{
                id: 'MS 10',
            }, {
                id: 'MS 12',
            }, {
                id: 'MS 14',
            }],
    },
    {
        id: 'NACKENENTSPANNUNG',
        name: 'Nackenentspannung',
        desc: "Ziel: brustöffnende Übungen und Verspannungen lösen",
        videos:
            [{
                id: 'MS 04',
            }, {
                id: 'MS 05',
            }, {
                id: 'MS 06',
            }, {
                id: 'MS 07',
            }, {
                id: 'MS 10',
            }, {
                id: 'MS 14',
            }],
    },
    {
        id: 'GOODMORNING',
        name: 'Good Morning',
        desc: "Ziel: Mobilisierung der Wirbelsäule in alle Bewegungsrichtungen",
        videos:
            [{
                id: 'MW 01',
            }, {
                id: 'MW 02',
            }, {
                id: 'MW 07',
            }, {
                id: 'MW 08',
            }, {
                id: 'MW 10',
            }, {
                id: 'MW 11',
            }],
    },
    {
        id: 'ENTLLENDEN',
        name: 'Entspannung Lendenwirbelsäule',
        desc: "Ziel: Entlastung der Lendenwirbelsäule und Verspannungen lösen",
        videos:
            [{
                id: 'MW 01',
            }, {
                id: 'MW 04',
            }, {
                id: 'MW 05',
            }, {
                id: 'MW 08',
            }, {
                id: 'MW 11',
            }],
    },
    {
        id: 'BRUSTWIRBEL',
        name: 'Brustwirbelsäulen Mobilisation',
        desc: "Ziel: Verbesserung der Beweglichkeit",
        videos:
            [{
                id: 'MW 03',
            }, {
                id: 'MW 05',
            }, {
                id: 'MW 06',
            }, {
                id: 'MW 07',
            }, {
                id: 'MW 09',
            }, {
                id: 'MW 10',
            }],
    },
    {
        id: 'OFFICEMOBILITY2',
        name: 'Office Mobility',
        desc: "Ziel: Verbesserung der Körperhaltung und Entspannung der Rückenmuskulatur",
        videos:
            [{
                id: 'MW 01',
            }, {
                id: 'MW 02',
            }, {
                id: 'MW 03',
            }, {
                id: 'MW 06',
            }, {
                id: 'MW 12',
            }],
    },
]


