import React from "react";
import HomeNav from "../client/HomeNav";

import titel from "../../assets/images/Titelbild.webp";

const mobi = [{
    title: "für Schulter",
    href: "/ms"
}, {
    title: "für Wirbelsäule",
    href: "/mw"
},];
const kra = [{
    title: "mit Körpergewicht",
    href: "/kk"
}, {
    title: "mit Widerstandsseil",
    href: "/kw"
}, {
    title: "mit Seilzug",
    href: "/ks"
}]
const pro = [{
    title: "Mobilisationsprogramme",
    href: "/programs-mobil"
}, {
    title: "Trainingsprogramme",
    href: "/programs"
}]

const Home: React.FC = () => {

    return (
        <main
            className="flex min-h-screen flex-col items-center p-3 sm:ml-64 lg:p-24">
            <div className={"mx-auto max-w-[1920px] flex flex-col items-center"}>
                <div
                    className={"min-w-full max-w-full flex flex-row items-start lg:items-center overflow-hidden"}>

                    <div
                        className={"flex-1 min-w-full max-w-full relative lg:min-w-0 min-h-72"}>
                        <div
                            className={"absolute lg:hidden top-0 left-0 before:absolute before:z-0 before:inset-0 before:bg-gray-100 before:opacity-50 lg:before:opacity-0"}>
                            <img src={titel} width={600} height={600}
                                 alt={"Preview"} className="max-w-full"/>
                        </div>
                        <div
                            className="flex flex-col relative z-10 max-w-fit items-start justify-center p-3 lg:border-l-[1px] lg:border-black">
                            <h1 className={"text-4xl font-bold uppercase text-accent xl:text-6xl 2xl:text-8xl"}>REHA
                                PULLEY</h1>
                            <span
                                className={"text-xl uppercase xl:text-3xl 2xl:text-5xl"}>Dein Trainingspartner</span>
                            <span
                                className={"text-xl uppercase xl:text-3xl 2xl:text-5xl"}>bei Schulterbeschwerden</span>
                            <ul className={"mt-5 hidden lg:block"}>
                                <HomeNav links={mobi} title="MOBILISATIONSÜBUNGEN"/>
                                <HomeNav links={kra} title="KRÄFTIGUNGSÜBUNGEN"/>
                                <HomeNav links={pro} title="MOBILISATIONS- UND TRAININGSPROGRAMME"/>
                            </ul>
                        </div>
                    </div>
                    <div className={"hidden flex-1 lg:block"}>
                        <img src={titel} width={600} height={600}
                             alt={"Preview"} className="max-w-full min-w-full"/>
                    </div>
                </div>
                <ul className={"mt-5 lg:hidden"}>
                    <HomeNav links={mobi} title="MOBILISATIONSÜBUNGEN"/>
                    <HomeNav links={kra} title="KRÄFTIGUNGSÜBUNGEN"/>
                    <HomeNav links={pro} title="MOBILISATIONS- UND TRAININGSPROGRAMME"/>
                </ul>
            </div>
        </main>
    )
}
export default Home;