import React, {useMemo} from 'react';
import './App.css';
import FavoriteProvider from "./components/client/FavoriteProvider";
import PlanProvider from "./components/client/PlanProvider";
import Header from "./components/Header";
import Footer from "./components/Footer";
import CookieModal from "./components/client/CookieModal";
import {COOKIE_CONSENT} from "./config/constants";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Home from "./components/nav/Home";
import Ks from './components/nav/Ks';
import Kk from './components/nav/Kk';
import Kw from './components/nav/Kw';
import Mw from './components/nav/Mw';
import Ms from './components/nav/Ms';
import Plan from "./components/nav/Plan";
import ProgramsMobilW from "./components/nav/ProgramsMobilW";
import ProgramsMobilS from "./components/nav/ProgramsMobilS";
import ProgramsMobil from "./components/nav/ProgramsMobil";
import Programs from "./components/nav/Programs";
import Favorites from "./components/nav/Favorites";

function App() {
    const consent = useMemo(() => {
        const str = document.cookie;
        return str.indexOf(COOKIE_CONSENT) !== -1;
    }, []);
    return (
        <div className="bg-gray-100 text-gray-900 dark:bg-gray-900 dark:text-white print:bg-white">
            <FavoriteProvider>
                <PlanProvider>
                    <BrowserRouter>
                        <Header/>
                        <Routes>
                            <Route path="*" element={<Home/>}/>
                            <Route path="/kk" element={<Kk/>}/>
                            <Route path="/ks" element={<Ks/>}/>
                            <Route path="/kw" element={<Kw/>}/>
                            <Route path="/ms" element={<Ms/>}/>
                            <Route path="/mw" element={<Mw/>}/>
                            <Route path="/plan" element={<Plan/>}/>
                            <Route path="/favorites" element={<Favorites/>}/>
                            <Route path="/programs" element={<Programs/>}/>
                            <Route path="/programs-mobil" element={<ProgramsMobil/>}/>
                            <Route path="/programs-mobil-s" element={<ProgramsMobilS/>}/>
                            <Route path="/programs-mobil-w" element={<ProgramsMobilW/>}/>
                        </Routes>
                    </BrowserRouter>
                </PlanProvider>
            </FavoriteProvider>

            <Footer/>
            <CookieModal initVis={!consent}/>
            <div className="hidden border-l-ftsblue"/>
            <div className="hidden border-l-ftsgreen"/>
            <div className="hidden border-l-ftspurple"/>
            <div className="hidden border-l-ftsyellow"/>
            <div className="hidden border-l-ftsred"/>
        </div>
    );
}

export default App;
