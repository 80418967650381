import React from "react";
import Wrapper from "../Wrapper";
import {KS} from "../../config/videos";
import VideoView from "../VideoView";
import s1 from "../../assets/images/s1.webp";
import s2 from "../../assets/images/s2.webp";
import s3 from "../../assets/images/s3.webp";
import s4 from "../../assets/images/s4.webp";

export default function PowerSeilzug() {
    return (
        <Wrapper title="Kräftigung Seilzug" titleColorClass="text-ftsyellow">
            <div
                className="min-w-full grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4">
                {KS.videos.map(v => (
                    <VideoView video={v} key={v.id} color={KS.color}
                               colorCss={KS.colorCss}/>
                ))}
            </div>

            <div
                className="w-full max-w-4xl mx-auto mt-8">
                <h3 className={"text-ftsyellow text-center font-bold text-2xl sm:text-3xl lg:text-4xl xl:text-5xl my-2 lg:my-4 print:hidden"}>Schwierigkeitslevel</h3>
                <div className="flex flex-row items-stretch my-2 lg:my-4">
                    <div className="flex-1 mr-0.5">
                        <div
                            className="py-2 bg-ftsyellow text-center relative after:absolute after:right-0 after:top-0 after:border-8 after:border-gray-100 dark:after:border-gray-900 after:border-l-transparent after:border-b-transparent">
                            <h4 className="text-lg text-white font-bold uppercase">Einfacher</h4>
                        </div>
                        <div
                            className={"bg-white dark:bg-black relative max-h-full after:absolute after:top-0 after:left-[50%] after:translate-x-[-50%] after:border-8 after:border-l-[2rem] after:border-r-[2rem] after:border-transparent after:border-t-ftsyellow"}>
                            <img src={s1}
                                 width={1868} height={2490}
                                 title={"Schrittstellung"} className={"min-w-full"}
                                 alt={"Schrittstellung"}/>
                        </div>
                        <div
                            className={"bg-gray-200 dark:bg-gray-800 py-2 text-center"}>
                            <span
                                className="block uppercase text-black dark:text-white font-bold text-sm">Schrittstellung</span>
                            <span className="block uppercase text-black dark:text-white text-sm">Mehr Stabilität</span>
                        </div>
                    </div>
                    <div className="flex-1 ml-0.5">
                        <div
                            className="py-2 bg-ftsyellow text-center relative after:absolute after:left-0 after:top-0 after:border-8 after:border-gray-100 dark:after:border-gray-900 after:border-r-transparent after:border-b-transparent">
                            <h4 className="text-lg text-white font-bold uppercase">Schwieriger</h4>
                        </div>
                        <div
                            className={"relative max-h-full after:absolute after:top-0 after:left-[50%] after:translate-x-[-50%] after:border-8 after:border-l-[2rem] after:border-r-[2rem] after:border-transparent after:border-t-ftsyellow"}>
                            <img src={s2}
                                 width={1868} height={2490}
                                 title={"Mehr Gewicht"} className={"min-w-full"}
                                 alt={"Mehr Gewicht"}/>
                        </div>
                        <div
                            className={"bg-gray-200 dark:bg-gray-800 py-2 text-center"}>
                            <span
                                className="uppercase text-black dark:text-white font-bold text-sm">Mehr Gewicht</span>
                        </div>
                    </div>
                </div>
                <div className="flex flex-row items-stretch my-2 lg:my-4">
                    <div className="flex-1">
                        <div
                            className="py-2 bg-ftsyellow text-center relative">
                            <h4 className="text-lg text-white font-bold uppercase">Schwieriger</h4>
                        </div>
                        <div
                            className={"relative max-h-full after:absolute after:top-0 after:left-[50%] after:translate-x-[-50%] after:border-8 after:border-l-[2rem] after:border-r-[2rem] after:border-transparent after:border-t-ftsyellow flex flex-row"}>
                            <div className="flex-1 mr-0.5">
                                <img src={s3}
                                     width={1868} height={2490}
                                     title={"Einbeinig"} className={"max-w-full"}
                                     alt={"Einbeinig"}/>
                            </div>
                            <div className="flex-1 ml-0.5">
                                <img src={s4}
                                     width={1868} height={2490}
                                     title={"instabiler Untergrund"} className={"max-w-full"}
                                     alt={"instabiler Untergrund"}/>
                            </div>
                        </div>
                        <div
                            className={"flex flex-row"}>
                            <div
                                className="flex-1 bg-gray-200 dark:bg-gray-800 py-2 mr-0.5 text-center">
                                <span
                                    className="block uppercase text-black dark:text-white font-bold text-sm">Einbeinig</span>
                                <span
                                    className="uppercase text-black dark:text-white text-sm">Fördert zusätzlich die Koordination, die Stabilität und das Gleichgewicht</span>
                            </div>
                            <div
                                className="flex-1 bg-gray-200 dark:bg-gray-800 py-2 ml-0.5 text-center">

                                <span
                                    className="block uppercase text-black dark:text-white font-bold text-sm">instabiler Untergrund / Augen Schliessen</span>
                                <span
                                    className="uppercase text-black dark:text-white text-sm">Fördert zusätzlich die propriozeptiven Fähigkeiten</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>
    )
}
